<div class="events-list">
  <div class="events-list-layout">
    <div class="events_wrap" *ngIf="selectedCommunicationType">
      <div class="events" *ngIf="events.length; else noEvents">
        <div class="event event_modal" *ngIf="events.length; else noEvents">
          <div
            class="item"
            [ngClass]="{
                selected: selectedEvent?.id === event.id,
                disabled: !event.active
              }"
            *ngFor="let event of events"
          >
            <div class="item_wrap" (click)="selectEvent(event)">
              {{ event.start_time * 1000 | date: 'HH:mm' }}
            </div>
          </div>
        </div>
      </div>

      <ng-template #noEvents>
        <span>{{'К сожалению на выбранную дату нет свободных слотов для записи' | translate}}</span>
      </ng-template>
    </div>

    <div class="actions">
      <button
        (click)="discardSignUp()"
        class="btn btn-secondary"
      >
       {{'Отменить' | translate}}
      </button>

      <button
        [disabled]="!selectedEvent || isDisabledInvite"
        (click)="invitePatient()"
        class="btn btn-primary"
      >
        {{'Пригласить' | translate}}
      </button>
    </div>
  </div>
</div>
