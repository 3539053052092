export const languagesConst = [
  {
    language: 'uk',
    fullName: 'Українська',
    shortName: 'УКР',
    image: '/assets/images/uk.png'
  },
  {
    language: 'en',
    fullName: 'English',
    shortName: 'EN',
    image: '/assets/images/en.png'
  },
  {
    language: 'ru',
    fullName: 'Русский',
    shortName: 'РУС',
    image: ''
  },
];
