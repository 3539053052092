import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {
  ConsultationResultFillComponent
} from './consultation-result-fill/consultation-result-fill.component';
import {
  ConsultationResultSuccessComponent
} from './consultation-result-success/consultation-result-success.component';
import {ConsultationResultComponent} from './consultation-result.component';

const routes: Routes = [
  {
    path: '',
    component: ConsultationResultComponent,
    children: [
      {
        path: ':id',
        component: ConsultationResultFillComponent
      },
      {
        path: 'success/:id',
        component: ConsultationResultSuccessComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultationResultRoutingModule {
}
