import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {filter, finalize} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {PageEvent} from "@angular/material/paginator";

import {NotificationsService} from '../../services/notifications.service';
import {Notification} from '../../interfaces/notification.interface';
import {ApiListFilter} from '@core/interfaces/list-filter.interface';
import {unsubscribe} from '@core/utils/unsubscriber';
import {ApiListResponse} from '@core/interfaces/list-response.interface';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit, OnDestroy {
  @Input() disableClick!: boolean;

  @Output() clicked: EventEmitter<null> = new EventEmitter();

  notifications: Notification[] = [];

  params: ApiListFilter = {
    page: 1,
    perPage: 6,
    field: 'created_at',
    direction: 'DESC'
  };

  total = 0;

  isLoading: boolean;
  isActive = false;

  private subscriptions: Subscription[] = [];

  constructor(private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {
    this.handleNotificationUpdated();
    this.getNotifications();
  }

  onScroll(): void {
    this.params.perPage = this.params.perPage + 6;
    this.getNotifications();
  }

  getNotifications(): void {
    this.isLoading = true;

    const getNotificationsSub = this.notificationsService
      .getNotifications(this.params)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe((result: ApiListResponse<Notification>) => {
        this.notifications = result.data;
        this.total = result.meta.total;
      });

    this.subscriptions.push(getNotificationsSub);
  }

  removeAll(event: Event): void {
    event.preventDefault();

    const removeAllSub = this.notificationsService.removeNotifications({is_all: 1})
      .subscribe(() => {
        this.params.page = 1;
        this.getNotifications();
      });

    this.subscriptions.push(removeAllSub);
  }

  onPageChange(event: PageEvent): void {
    this.params.page = event.pageIndex + 1;
    this.params.perPage = event.pageSize;

    this.getNotifications();
  }

  onMenuClosed(): void {
    this.params.page = 1;
    this.params.perPage = 6;
    this.isActive = false;
  }

  onMenuOpened(): void {
    this.getNotifications();
    this.isActive = true;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private handleNotificationUpdated(): void {
    const notificationsSub = this.notificationsService.notificationsUpdated$
      .pipe(filter((event: boolean | null) => event))
      .subscribe(() => {
        this.params.page = 1;
        this.getNotifications();
      });
    this.subscriptions.push(notificationsSub);
  }
}
