import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import {finalize, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {combineLatest} from 'rxjs';

import {buildWorkPointsDate} from '@core/utils/build-work-points-date';
import {WorkPoints} from '@core/interfaces/work-points/work-points.interface';
import {GetWorkPointsFilter} from '@core/interfaces/work-points/work-points-filter.interface';
import {WorkPointsService} from '@core/services/work-points.service';
import {scrollToHeader} from '@core/utils/scroll-to-header';
import {ModalInfoComponent} from '@shared-modules/modals/modal-info/modal-info.component';
import {ProfileService} from '@profile/profile.service';
import {TypeCommunicationEnum} from '@core/enums/type-communication.enum';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {Clinic} from '../../../../clinics/interfaces/clinic.interface';

@UntilDestroy()

@Component({
  selector: 'app-doctor-work-points',
  templateUrl: './doctor-work-points.component.html',
  styleUrls: ['./doctor-work-points.component.scss']
})
export class DoctorWorkPointsComponent implements OnInit {
  @Input() patientId: number;

  @Output() close = new EventEmitter();

  selectedDay: moment.Moment;

  firstEventDate: moment.Moment;

  selectedPoint: WorkPoints;
  profile: Doctor;
  clinic: Clinic;
  selectedTypeCommunication: number;

  typeCommunicationsEnum = TypeCommunicationEnum;

  params: GetWorkPointsFilter = {
    doctorId: null,
    from: moment().add(10, 'minutes').utc().unix(),
    to: moment().add(1, 'month').utc().unix(),
    group: 1,
    booked: 0,
    status: 1,
    type_communications: []
  };

  events: string[] = [];

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private workPointsService: WorkPointsService,
    private profileService: ProfileService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.getWorkPoints();
  }

  onSelectDate(event: moment.Moment): void {
    this.selectedDay = event;
    this.selectedPoint = null;
  }

  selectedWorkPoint(point: WorkPoints): void {
    this.selectedPoint = point;
  }

  closeModal(): void {
    this.close.emit();
  }

  selectType(event: number): void {
    this.selectedTypeCommunication = event;
    this.params.type_communications = [event, TypeCommunicationEnum.Alltypes];

    this.workPointsService.getWorkPoints(this.params)
      .pipe(untilDestroyed(this))
      .subscribe((result: WorkPoints[]) => this.setWorkPoints(result));
  }

  private getWorkPoints(): void {
    combineLatest([
      this.profileService.profile$,
      this.profileService.activeClinic$
    ])
      .pipe(
        mergeMap(([profile, clinic]) => {
          this.profile = profile;
          this.clinic = clinic;
          this.params.doctorId = profile.id;
          this.params.type_communications = clinic.hasOfflineConsultation
            ? [TypeCommunicationEnum.Online, TypeCommunicationEnum.Offline, TypeCommunicationEnum.Alltypes]
            : [TypeCommunicationEnum.Online];

          if (!clinic.hasOfflineConsultation) {
            this.selectedTypeCommunication = TypeCommunicationEnum.Online;
          }

          return this.workPointsService.getWorkPoints(this.params);
        }),
        finalize(() => scrollToHeader()),
        untilDestroyed(this)
      )
      .subscribe((result: WorkPoints[]) => this.setWorkPoints(result));
  }

  private setWorkPoints(points: WorkPoints[]): void {
    if (points.length) {
      this.firstEventDate = moment(points['0'].data);
      this.selectedDay = this.firstEventDate;
      this.events = buildWorkPointsDate(points);
      return;
    }

    this.resolveEmptyWorkPoints();
  }

  private resolveEmptyWorkPoints(): void {
    this.events = [];

    const dialogRef = this.dialog.open(ModalInfoComponent, {
      data: {
        title: 'Извините',
        message: 'У вас нет свободных слотов. Пожалуйста, сначала создайте расписание на будущее',
        btnText: 'Создать',
        showBtn: true
      },
      panelClass: ['primary-modal'],
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(res => {
        if (typeof (res) === 'boolean') {
          this.close.emit();
          this.router.navigate(['/schedule']);
        }
      });
  }
}
