export const environment = {
  production: false,
  doctors4uaUrl: `${(window as any).environments.apiUrlDev}`,
  doctors4uaUrlV2: `${(window as any).environments.apiUrlDev}/v2`,
  doctors4uaAppUrl: `${(window as any).environments.patientAppUrlDev}`,
  socketApi: 'wss://centrifugo.doctors4ua.com/connection/websocket',
  portalApi: 'https://develop.doctors4ua.com',
  siteKey: '6LfQOkwhAAAAAMSC2o4ZbWUEmMZ4LHV37A27IZ2o',
  loggerConfig: {
    // socket
    socketInfo: true,
    socketError: true,
    // mediaChat
    mediaChatInfo: true,
    mediaChatError: true,
    // notification
    notificationInfo: true,
    // waitingArea
    waitingAreaInfo: true,
    // onlineChat
    onlineChatInfo: true,
    // dialogueChat
    dialogueInfo: true,
    // sidebar
    sidebarInfo: true,
  }
};
