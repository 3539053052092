import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  OnDestroy,
  Optional
} from '@angular/core';
import {MatCalendar, MatDatepickerIntl} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from '@angular/material/core';

import {CalendarService} from '../calendar.service';
import {ProfileService} from '@profile/profile.service';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent implements OnDestroy {
  label: string;
  private subscriptions: any[] = [];

  constructor(
    private _intl: MatDatepickerIntl,
    @Inject(forwardRef(() => MatCalendar)) public calendar: MatCalendar<Date>,
    @Optional() private _dateAdapter: DateAdapter<Date>,
    @Optional() @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    changeDetectorRef: ChangeDetectorRef,
    private calendarService: CalendarService,
    private profileService: ProfileService
  ) {
    this.setLocale();
    const s = this.calendar.stateChanges.subscribe(() => changeDetectorRef.markForCheck());
    this.label = this.getLabel();
    const s2 = this.calendarService.label$.subscribe((label: string) => {
      if (label) {
        this.label = label;
      }
    });

    this.subscriptions.push(s, s2);
  }

  get prevButtonLabel(): string {
    return this._intl.prevMonthLabel;
  }

  get nextButtonLabel(): string {
    return this._intl.nextMonthLabel;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  getLabel(): string {
    return this._dateAdapter.format(
      this._dateAdapter.createDate(
        this._dateAdapter.getYear(this.calendar.activeDate),
        this._dateAdapter.getMonth(this.calendar.activeDate),
        1
      ),
      this._dateFormats.display.monthYearA11yLabel
    );
  }

  previousClicked(): void {
    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    this.calendarService.monthChange$.next(this.calendar.activeDate);
    this.label = this.getLabel();
  }

  nextClicked(): void {
    this.calendar.activeDate = this._dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    this.calendarService.monthChange$.next(this.calendar.activeDate);
    this.label = this.getLabel();
  }

  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }

    return (
      !this.calendar.minDate || !this._isSameView(this.calendar.activeDate, this.calendar.minDate)
    );
  }

  nextEnabled(): boolean {
    return (
      !this.calendar.maxDate || !this._isSameView(this.calendar.activeDate, this.calendar.maxDate)
    );
  }

  private _isSameView(date1: Date, date2: Date): boolean {
    return (
      this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2) &&
      this._dateAdapter.getMonth(date1) == this._dateAdapter.getMonth(date2)
    );
  }

  private setLocale(): void {
    const localeSub = this.profileService.currentLang$
      .subscribe(lang => this._dateAdapter.setLocale(lang.locale));

    this.subscriptions.push(localeSub);
  }
}
