import {Component, OnInit} from '@angular/core';
import {Crypto} from '@core/classes/crypto.class';
import {TranslateService} from '@ngx-translate/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {ProfileService} from '@profile/profile.service';
import {localToken} from './app.constants';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {WindowSizeService} from '@core/services/window-size.service';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {Title} from '@angular/platform-browser';
import {CentrifugoSocketService} from "@core/services/centrifugo-socket.service";

@UntilDestroy()

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [NetworkStateService]
})
export class AppComponent implements OnInit {
  crypto: Crypto = new Crypto();

  favIcon: HTMLLinkElement = document.querySelector('#favicon');

  constructor(
    private profileService: ProfileService,
    private networkStateService: NetworkStateService,
    private windowSizeService: WindowSizeService,
    private translateService: TranslateService,
    private centrifugoSocketService: CentrifugoSocketService,
    private titleService: Title,
  ) {
  }

  ngOnInit(): void {
    this.storageListener();

    this.setLanguage();
    this.networkStateService.checkNetworkState();

    if (this.profileService.isAuthenticated()) {
      this.handleWindowSizeConfig();
    }

    this.setFaviconAndTitle();
  }


  private storageListener(): void {
    window.addEventListener('storage', (event) => {
      if (event.key === this.crypto.encrypt(localToken) && !event.newValue) {
        this.centrifugoSocketService.socketDisconnect();
        this.profileService.logout();
      }
    });
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.handleWindowSizeConfig()
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private setFaviconAndTitle(): void {
    this.favIcon.href = `/assets/clinics/${(window as any).environments.directory}/favicon.ico`;
    this.titleService.setTitle((window as any).environments.clinicName);
  }

  private setLanguage(): void {
    const browserLang = this.translateService.getBrowserLang();
    const language = browserLang === 'en' || browserLang === 'uk' || browserLang === 'ru' ? browserLang : 'uk';
    this.translateService.setDefaultLang(language);

    this.profileService.profile$
      .pipe(
        filter(r => !!r),
        mergeMap((profile: Doctor) => {
          if (!profile.lang) {
            return this.profileService.patchLanguage(language);
          }

          return of(profile);
        }),
        untilDestroyed(this)
      )
      .subscribe((profile: Doctor) => {
        this.translateService.setDefaultLang(profile.lang);
      });
  }
}
