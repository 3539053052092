import {
  ApiSpecialization,
  Specialization
} from '../../interfaces/specialization/specialization.interface';

export function prepareSpecialization(apiEntity: ApiSpecialization): Specialization {
  if (!apiEntity) {
    return;
  }

  const data: Specialization = {
    ...apiEntity.data.attributes,
    id: apiEntity.data.id,
  };

  const relationships = apiEntity.data.relationships;

  if (relationships) {
    if (relationships.reason_treatment?.data) {
      data.reason_treatment = relationships.reason_treatment.data;
    }
  }

  return data;
}
