import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ModalConfirmInterface} from './modal-confirm.interface';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html'
})
export class ModalConfirmComponent implements OnInit{
  isArray = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalConfirmInterface) {
  }

  ngOnInit() {
    this.isArray = Array.isArray(this.data.message);
  }
}
