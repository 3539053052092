import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

import {ProfileService} from '@profile/profile.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {WindowSizeService} from '@core/services/window-size.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isHeaderShortened: boolean;

  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();

  windowSizeConfig: WindowSizeConfigInterface;

  showIcons = true;

  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private profileService: ProfileService,
    private windowSizeService: WindowSizeService
  ) {
    this.handleWindowSizeConfig();
  }

  ngOnInit(): void {
    this.showIcons = !this.router.url.includes('wizard');
    this.watchRouterEvents();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private handleWindowSizeConfig(): void {
    const windowSizeSub = this.windowSizeService.windowSizeConfig$
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => this.windowSizeConfig = windowSizeConfig);
    this.subscriptions.push(windowSizeSub);
  }

  private watchRouterEvents(): void {
    const routerEventsSub = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.showIcons = !event?.urlAfterRedirects.includes('wizard');
    });
    this.subscriptions.push(routerEventsSub);
  }
}
