<a
  *ngIf="profile$ | async as profile"
  (click)="disableClick ? $event.preventDefault() : gotoProfile()"
  [class.disabled]="disableClick"
  routerLinkActive="active"
  class="profile"
>
  <div class="profile_avatar">
    <img src="assets/images/avatar_icon.svg" alt="avatar">
  </div>
</a>
