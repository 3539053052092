<div class="modal_content">
  <div class="modal_image">
    <img
      [src]="data.isAttention ? ('attention.svg' | imagePath) : ('confirm.svg' | imagePath)"
      alt="attention"
    >
  </div>

  <div class="modal_wrap">
    <app-modal-header [title]="data.title | translate"></app-modal-header>

    <div class="dialog-content">
      <p class="text-center" *ngIf="!isArray; else stringArray">{{ data.message | translate }}</p>
      <ng-template #stringArray>
        <p class="text-center">
          <span *ngFor="let string of data.message">{{ string | translate }}</span>
        </p>
      </ng-template>
      <mat-dialog-actions>
        <button class="btn btn-secondary" [mat-dialog-close]="false">{{'Нет' | translate }}</button>

        <button class="btn btn-primary" [mat-dialog-close]="true">{{'Да' | translate}}</button>
      </mat-dialog-actions>
    </div>
  </div>
</div>
