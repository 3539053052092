<div class="calendar-wrapper">
  <mat-calendar
    [dateClass]="dateClass"
    [class.disabled]="!selectedCommunicationType"
    #calendar
    [headerComponent]="header"
    [(selected)]="selectedDate"
    (selectedChange)="onDateChanged(); onEducationSelectDate()"
    [minDate]="minDate"
    [maxDate]="maxDate"
  ></mat-calendar>
</div>
