import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Crypto} from '../../../core/classes/crypto.class';
import {deleteCookie, getCookie, setCookie} from '../../../core/utils/cookie';
import {
  CopiedRecommendationInterface, RecommendationFormDataInterface
} from '@shared-modules/medications/interfaces/apointment-form.interface';
import {AppointmentFormEnum} from '@shared-modules/medications/enums/appointment-form.enum';

@Injectable({
  providedIn: 'root'
})
export class AppointmentFormService {
  cookiesOptions: {
    expires: Date;
  };

  crypto: Crypto = new Crypto();

  updateFormData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  consultationId$: BehaviorSubject<any> = new BehaviorSubject<number | null>(null);
  hasMedication$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasDirection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  getValueFromCookie(consultationId): RecommendationFormDataInterface {
    let idea = getCookie(AppointmentFormEnum.Idea + consultationId);
    let diagnosis = getCookie(AppointmentFormEnum.Diagnosis + consultationId);
    let recommendation = getCookie(AppointmentFormEnum.Recommendation + consultationId);

    if (idea) {
      idea = this.crypto.decrypt(idea);
    }

    if (diagnosis) {
      diagnosis = this.crypto.decrypt(diagnosis);
    }

    if (recommendation) {
      recommendation = this.crypto.decrypt(recommendation);
    }

    return {
      idea: idea || '',
      diagnosis: diagnosis || '',
      recommendation: recommendation || ''
    };
  }

  updateCookie(form: RecommendationFormDataInterface, consultationId): void {
    setCookie(
      AppointmentFormEnum.Idea + consultationId,
      this.crypto.encrypt(form.idea),
      this.cookiesOptions
    );

    setCookie(
      AppointmentFormEnum.Diagnosis + consultationId,
      this.crypto.encrypt(form.diagnosis),
      this.cookiesOptions
    );

    setCookie(
      AppointmentFormEnum.Recommendation + consultationId,
      this.crypto.encrypt(form.recommendation),
      this.cookiesOptions
    );
  }

  removeCookie(consultationId): void {
    deleteCookie(AppointmentFormEnum.Idea + consultationId);
    deleteCookie(AppointmentFormEnum.Diagnosis + consultationId);
    deleteCookie(AppointmentFormEnum.Recommendation + consultationId);
  }

  concatCopiedTexts(messages: CopiedRecommendationInterface[], fieldType: string): string {
    let message = '';
    messages.forEach(item => {
      if (item.field === fieldType) {
        message += `${item.message} `;
      }
    });

    return message;
  }
}
