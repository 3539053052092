import {Injectable} from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';

import {ProfileService} from '@profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private profileService: ProfileService
  ) {
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.profileService.isAuthenticated()) {
      this.router.navigate(['/consultations']);
      return false;
    }

    return true;
  }
}
