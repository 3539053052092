import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

import {buildErrorMessage} from '@core/utils/build-error-message';
import {ModalInfoComponent} from '@shared-modules/modals/modal-info/modal-info.component';
import {ModalInfoInterface} from '@shared-modules/modals/modal-info/modal-info.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalInfoService {
  dialogRefSub;

  constructor(public dialog: MatDialog, private router: Router) {
  }

  onSuccess(params: ModalInfoInterface): void {
    this.openModal(params);
  }

  onError(error: any, title?: string, router?: string): void {
    const params: ModalInfoInterface = {
      message: buildErrorMessage(error),
      type: 'error',
      title,
      router
    };

    this.openModal(params);
  }

  openModal(params: ModalInfoInterface): void {
    this.dialogRefSub?.unsubscribe();

    const dialogRef = this.dialog.open(ModalInfoComponent, {
      data: {...params},
      panelClass: ['primary-modal', 'modal-sm'],
      autoFocus: false
    });

    if (params.isClose) {
      setTimeout(() => dialogRef.close(false), 3000);
    }

    if (params.router) {
      this.dialogRefSub = dialogRef.afterClosed()
        .subscribe(() => this.router.navigate([params.router]));
    }
  }
}
