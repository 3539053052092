<div class="modal_wrap">
  <span class="title">{{ 'Жалобы и диагноз' | translate}}</span>

  <div class="dialog-content">
    <form [formGroup]="form" class="form">
      <div class="field_set">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Жалобы' | translate }}</mat-label>

            <textarea
              matInput
              [maxlength]="maxDiagnosisLength"
              [rows]="4"
              formControlName="idea"
              cdkTextareaAutosize
            ></textarea>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Диагноз' | translate }}</mat-label>

            <textarea
              matInput
              [maxlength]="maxDiagnosisLength"
              [rows]="4"
              formControlName="diagnosis"
              cdkTextareaAutosize
              [required]="data.isResultPage"
            ></textarea>

            <mat-error>
              <app-validator-message [field]="form.get('diagnosis')"></app-validator-message>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="attention">
        <mat-icon>info</mat-icon>

        <span>
          {{ 'Общие рекомендации относятся к лечению в целом. Лекарства можно указать отдельно в разделе "Лечение"' | translate }}
        </span>
      </div>

      <div class="field recommendation">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Рекомендации' | translate }}</mat-label>

          <textarea
            matInput
            [maxlength]="maxRecommendationLength"
            [rows]="4"
            formControlName="recommendation"
            cdkTextareaAutosize
          ></textarea>

          <mat-error>
            <app-validator-message [field]="form.get('recommendation')">
            </app-validator-message>
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <mat-dialog-actions>
      <button
        class="btn btn-secondary"
        (click)="closeModal()"
      >
        {{ 'Отменить' | translate }}
      </button>

      <button
        class="btn btn-primary"
        (click)="saveRecommendation()"
      >
        {{ 'Сохранить' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
