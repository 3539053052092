import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagePath'
})
export class ImagePathPipe implements PipeTransform {

  transform(image: string): string {
    let host;

    switch (true) {
      case window.location.host.includes('localhost'):
        host = 'doctor.doctors4ua.com';
        break;
      case window.location.host.includes('doctor-dev'):
        host = 'doctor.doctors4ua.com';
        break;
      case window.location.host.includes('doctor.bekey.io'):
        host = 'doctor.doctorkharkovkids.com.ua'; //doctor.your-baby.com.ua
        break;
      case window.location.host.includes('doctorsomnycare.bekey.io'):
        host = 'doctor.omnycare.com';
        break;
      default:
        host = window.location.host;
    }

    return `/assets/clinics/${host}/images/${image}`;
  }
}
