import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';

import {ModalFindUserComponent} from './modal-find-user.component';
import {EmptyResultModule} from '../../empty-result/empty-result.module';
import {SpinnerModule} from '../../spinner/spinner.module';
import {PatientModule} from '../../cards/patient/patient.module';
import {PatientsContextSearchModule} from '../../patients-context-search/patients-context-search.module';
import {TranslateModule} from '../../../core/pipes/translate/translate.module';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';


@NgModule({
  declarations: [
    ModalFindUserComponent
  ],
  imports: [
    CommonModule,
    EmptyResultModule,
    SpinnerModule,
    PatientModule,
    MatPaginatorModule,
    PatientsContextSearchModule,
    TranslateModule,
    SafeImageModule
  ]
})
export class ModalFindUserModule {
}
