import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PreventEnterDirective} from './prevent-enter.directive';


@NgModule({
  declarations: [
    PreventEnterDirective
  ],
  exports: [
    PreventEnterDirective
  ],
  imports: [
    CommonModule
  ]
})
export class PreventEnterModule {
}
