import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

import {FileUploaderService} from '@core/services/file-uploader.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {FileExtensionsEnum, FileTypesEnum} from '@core/enums/file-types.enum';
import {FilePreviewInterface} from '@entry-components/file-preview/file-preview.interface';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit, OnDestroy {
  fileTypes = FileTypesEnum;
  fileExtensions = FileExtensionsEnum;

  file;

  isPDFFile = this.checkPDF();

  isError = false;

  isLoading = true;

  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FilePreviewInterface,
    public dialogRef: MatDialogRef<FilePreviewComponent>,
    private fileUploaderService: FileUploaderService,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    if (this.data.localUrl && this.data.fileName) {
      this.isError = true;
      this.isLoading = false;

      return;
    }

    if (this.data.localUrl) {
      const urlString = this.data?.localUrl as string;

      this.file = this.isPDFFile
        ? {url: this.base64ToArrayBuffer(urlString?.split(',')[1])}
        : {url: urlString};

      if (!this.isPDFFile) {
        this.isLoading = false;
      }

      this.file.secureUrl = this.sanitizer
        .bypassSecurityTrustResourceUrl(this.data.localUrl as string);

      return;
    }

    this.isPDFFile ? this.buildPdfByToken() : this.buildImageByToken();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  documentLoaded(): void {
    this.isLoading = false;
  }

  private buildImageByToken(): void {
    const fileUploaderSub = this.fileUploaderService
      .buildImageByToken(
        this.data.fileName ? this.data.fileName : this.data.name,
        this.data.storage,
        this.data.anamnesisId)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (result: SafeUrl) => this.file = {url: result},
        () => this.isError = true
      );
    this.subscriptions.push(fileUploaderSub);
  }

  private buildPdfByToken(): void {
    const fileUploaderSub = this.fileUploaderService
      .buildPdfByToken(
        this.data.fileName ? this.data.fileName : this.data.name,
        this.data.storage,
        this.data.anamnesisId)
      .subscribe(
        (result) => this.file = result,
        () => this.isError = true
      );
    this.subscriptions.push(fileUploaderSub);
  }

  private checkPDF(): boolean {
    return this.data.type === this.fileTypes.PDF
      || this.data.type === this.fileExtensions.PDF
      || this.data.name?.split('.')[1] === this.fileExtensions.PDF;
  }

  private base64ToArrayBuffer(base64): ArrayBufferLike {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
