import { Component, OnInit } from '@angular/core';
import {appStoreLink, googlePlayLink} from '../../../../../app.constants';

@Component({
  selector: 'app-apps-links',
  templateUrl: './apps-links.component.html',
  styleUrls: ['./apps-links.component.scss']
})
export class AppsLinksComponent implements OnInit {
  googlePlayLink = googlePlayLink;
  appStoreLink = appStoreLink;


  constructor() { }

  ngOnInit(): void {
  }

}
