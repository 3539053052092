<mat-sidenav-container>
  <mat-sidenav-content>

    <app-header
      id="main-header"
      *ngIf="windowSizeConfig?.isTablet || !hideElementsByRoute"
      [isHeaderShortened]="appLayoutConfig?.isHeaderShortened"
      (toggleSidenav)="sidenav.toggle()"
    ></app-header>

    <div class="wrapper-layout">
      <div
        class="app-layout"
        [class.mobile-chat]="!windowSizeConfig?.isTablet && hideElementsByRoute"
      >
        <div class="app-layout-main">
          <app-nearest-consultation
            *ngIf="!hideElementsByRoute && (showNearestConsultation$ | async)"
          ></app-nearest-consultation>

          <router-outlet></router-outlet>
        </div>
      </div>

      <div class="wrapper_img" *ngIf="isResultPage">
        <img
          [src]="
          windowSizeConfig?.isTablet
            ? ('consultation_end.svg' | imagePath)
            : ('heart.svg' | imagePath)"
          alt="end"
        >
      </div>
    </div>

    <app-footer *ngIf="windowSizeConfig?.isTablet && !hideElementsByRoute"></app-footer>
  </mat-sidenav-content>

  <mat-sidenav #sidenav [mode]="'over'" [position]="'start'">
    <app-sidenav
      class="sidenav"
      *ngIf="!windowSizeConfig?.isLargeDesktop"
      (toggleSidenav)="sidenav.toggle()"
      (closeSidenav)="sidenav.close()"
    ></app-sidenav>
  </mat-sidenav>
</mat-sidenav-container>


<app-new-notification-listener
  *ngIf="!appLayoutConfig?.isNewNotificationsHidden"
  (clicked)="sidenav.close()"
></app-new-notification-listener>
