import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';

import {Consultation} from '@consultations/interfaces/consultation.interface';
import {GetConsultationByIdFilter} from '@consultations/interfaces/consultations-filter.interface';
import {ProfileService} from '@profile/profile.service';
import {ConsultationsService} from '@consultations/consultations.service';
import {ModalInfoService} from '@core/services/modal-info.service';
import {DoctorStatusesEnum} from '@core/enums/doctor-statuses.enum';
import {
  PatientsInviteComponent
} from '../../../dashboard/patients-invite/patients-invite.component';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';
import {FilePreviewInterface} from '@entry-components/file-preview/file-preview.interface';
import {FileTypesEnum} from '@core/enums/file-types.enum';

@UntilDestroy()

@Component({
  selector: 'app-success-content',
  templateUrl: './success-content.component.html',
  styleUrls: ['./success-content.component.scss']
})
export class SuccessContentComponent implements OnInit {
  @Input() inModal = false;
  @Input() offlineConsultationId = null;
  @Output() closeModal = new EventEmitter();

  consultationId: number;
  consultation: Consultation;

  params: GetConsultationByIdFilter = {
    relation_anamnes: 1,
    relation_patient: 1,
    with_recommendation: 1,
    doctor_with_review: 1,
    relation_pdf: 1
  };

  constructor(
    private profileService: ProfileService,
    private consultationsService: ConsultationsService,
    private modalInfoService: ModalInfoService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.consultationId = this.route.snapshot.params.id || this.offlineConsultationId;
    this.getConsultation(this.consultationId);
  }

  manageSuccess(): void {
    if (this.inModal) {
      this.closeModal.emit();
      return;
    }

    this.router.navigate(['consultations']);
  }

  invitePatient(): void {
    if (this.inModal) {
      this.closeModal.emit();
    }

    const profile = this.profileService.profile$.getValue();
    if (profile?.additional_info?.status === DoctorStatusesEnum.NotActive) {
      this.modalInfoService.onError(
        `
        Вы не можете пригласить пациента на консультацию,
        поскольку Ваш профиль закрыт и отсутствуют слоты для записи`,
        ''
      );

      return;
    }

    this.dialog.open(PatientsInviteComponent, {
      data: {
        patientId: this.consultation.patient.id
      },
      panelClass: ['primary-modal', 'modal-sm'],
      autoFocus: false
    });
  };

  previewFile(storage: string): void {
    const data: FilePreviewInterface = {
      type: FileTypesEnum.PDF,
      name: this.consultation.consultationPdf.name,
      storage: storage,
      title: storage.includes('direction') ? 'Направления' : 'Лечение'
    };

    this.dialog.open(FilePreviewComponent, {
      data,
      panelClass: ['primary-modal', 'modal-xl'],
      autoFocus: false
    });
  }

  private getConsultation(id: number): void {
    this.consultationsService.getConsultationById(id, this.params)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => this.consultation = res.data,
        () => this.router.navigate(['not-found'])
      );
  }
}
