import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dateAsAgo'
})
export class DateAsAgoPipe implements PipeTransform {

  transform(value: any, lang: any): string {
    let time = (Date.now() - Date.parse(value)) / 1000;
    const oneDay = 24 * 60 * 60;

    if (Math.floor(time / oneDay) > 7) {
      return value.format('DD.MM.YY');
    }

    if (time < 60) {
      const now = {
        uk: 'зараз',
        ru: 'сейчас',
        en: 'now'
      };

      return now[lang.code];
    }

    const divider = [60, 60, 24, 30, 12];
    const string = {
      uk: [' сек', ' хв', ' год', ' день'],
      ru: [' сек', ' мин', ' ч', ' день'],
      en: [' sec', ' min', ' h', ' day']
    };

    if (Math.floor(time / oneDay) > 1) {
      string.en[string.en.length - 1] = ' days';
    }

    if (Math.floor(time / oneDay) > 1 && Math.floor(time / oneDay) < 5) {
      string.ru[string.ru.length - 1] = ' дня';
      string.uk[string.uk.length - 1] = ' дні';
    }

    if (Math.floor(time / oneDay) >= 5) {
      string.ru[string.ru.length - 1] = ' дней';
      string.uk[string.uk.length - 1] = ' днів';
    }

    let i;
    for (i = 0; Math.floor(time / divider[i]) > 0; i++) {
      time /= divider[i];
    }

    return Math.floor(time) + string[lang.code][i];
  }

}
