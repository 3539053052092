import {DirectionFieldsEnum} from "../enums/direction-fields.enum";

export const DirectionsFieldsLabelsConst = [
  {
    type: DirectionFieldsEnum.Doctors,
    label: 'К другому врачу (укажите к какому)'
  },
  {
    type: DirectionFieldsEnum.Analizes,
    label: 'Анализы (укажите какие)'
  },
  {
    type: DirectionFieldsEnum.Examination,
    label: 'Обследование (укажите какое)'
  }
]
