<div class="type" *ngIf="clinic?.hasOfflineConsultation">
  <div class="type_title">{{ 'Оберіть тип консультації' | translate }}:</div>

  <mat-radio-group
    class="type_list"
    (change)="selectType($event.value)">
    <mat-radio-button
      class="type_item"
      [value]="typeCommunicationsEnum.Online">
      {{ 'Онлайн' | translate }}
    </mat-radio-button>

    <mat-radio-button
      class="type_item"
      [value]="typeCommunicationsEnum.Offline">
      {{ 'В клинике' | translate }}
    </mat-radio-button>
  </mat-radio-group>

  <div class="address" *ngIf="selectedPoint?.clinicAddressId && typeCommunicationsEnum.Offline">
    <span>{{ selectedPoint.clinicAddressId | addressById }}</span>
  </div>
</div>

<div class="calendar">
  <app-calendar
    [events]="events"
    [firstEventDate]="firstEventDate"
    [selectedCommunicationType]="selectedTypeCommunication"
    (selectDate)="onSelectDate($event)"
  ></app-calendar>
</div>

<app-today-event-list
  [doctor]="profile"
  [patientId]="patientId"
  [hasOfflineConsultation]="clinic?.hasOfflineConsultation"
  [selectedCommunicationType]="selectedTypeCommunication"
  [selectedDay]="selectedDay"
  (closeModal)="closeModal()"
  (selectedWorkPoint)="selectedWorkPoint($event)"
></app-today-event-list>
