import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';

import {PatientsInviteComponent} from './patients-invite.component';
import {
  PatientsContextSearchModule
} from '@shared-modules/patients-context-search/patients-context-search.module';
import {DoctorSearchModule} from '@shared-modules/doctor-search/doctor-search.module';
import {SelectedDoctorComponent} from './components/selected-doctor/selected-doctor.component';
import {
  DoctorWorkPointsComponent
} from './components/doctor-work-points/doctor-work-points.component';
import {TodayEventListComponent} from './components/today-event-list/today-event-list.component';
import {ConsultationsService} from '@consultations/consultations.service';
import {WorkPointsService} from '@core/services/work-points.service';
import {DoctorsService} from '@profile/services/doctors.service';
import {PatientModule} from '@shared-modules/cards/patient/patient.module';
import {CalendarModule} from '@shared-modules/calendar/calendar.module';
import {SpecializationService} from '@core/services/specialization.service';
import {SafeImageModule} from '@shared-modules/safe-image/safe-image.module';
import {
  SpecializationNamesModule
} from '@shared-modules/specialization-names/specialization-names.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {AddressByIdModule} from '@core/pipes/address-by-id/address-by-id.module';


@NgModule({
  declarations: [
    PatientsInviteComponent,
    SelectedDoctorComponent,
    DoctorWorkPointsComponent,
    TodayEventListComponent
  ],
  imports: [
    CommonModule,
    PatientsContextSearchModule,
    DoctorSearchModule,
    CalendarModule,
    PatientModule,
    SafeImageModule,
    MatIconModule,
    SpecializationNamesModule,
    TranslateModule,
    MatRadioModule,
    AddressByIdModule
  ],
  providers: [
    WorkPointsService,
    DoctorsService,
    ConsultationsService,
    SpecializationService
  ]
})
export class PatientsInviteModule {
}
