<div class="page-container">
  <p class="section-title">
    {{ isDialogueModal ? ('Начать новый диалог' | translate) : ('Пригласить пациента' | translate) }}
  </p>

  <app-patients-context-search
    *ngIf="!firstRequest || patients?.length"
    (search)="onSearch($event)"
    [isMyPatientsOnly]="true"
  ></app-patients-context-search>

  <ng-container *ngIf="patients?.length && !isLoading; else empty">
    <div class="card-list-patients">
      <div class="card-wrapper" *ngFor="let patient of patients">

        <div class="card patient-card">
          <div class="body">
            <div class="user">
              <div class="avatar" [class.avatar-exist]="patient.avatar">
                <ng-container *ngIf="patient.avatar; else image">
                  <app-safe-image
                    [name]="patient.avatar"
                    [storage]="patient.avatar_storage"
                  ></app-safe-image>
                </ng-container>

                <ng-template #image>
                  <img src="/assets/images/default-avatar-patient.svg" alt="Doctors4UA Patient"/>
                </ng-template>
              </div>

              <div class="patient_info">
                <div class="name">
                  <div class="name_full"
                       *ngIf="patient?.surname && patient?.name; else username">
                    {{ patient?.surname }}
                    {{ patient?.name }}
                    {{ patient?.middle_name }}
                  </div>
                  <ng-template #username>
                    <div class="name_full">
                      {{ patient.username.length < 20 ? patient.username : patient.username.slice(0, 20) + '...' }}
                    </div>
                  </ng-template>
                </div>

                <div class="consultation_info" *ngIf="isDialogueModal">
                  <ng-container
                    *ngIf="patient?.last_consultation.start_time_end_consultation; else noConsultations"
                  >
                    {{ 'Крайняя консультация:' | translate }}
                    <span>
                        {{ patient.last_consultation.start_time_end_consultation | date: 'dd.MM.YYYY HH:mm' }}
                      </span>
                  </ng-container>

                  <ng-template
                    #noConsultations>{{ 'Нет пройденных консультаций' | translate }}
                  </ng-template>
                </div>
              </div>
            </div>

            <div class="consultation_info_mobile" *ngIf="isDialogueModal">
              <ng-container
                *ngIf="patient?.last_consultation.start_time_end_consultation; else noConsultations"
              >
                {{ 'Крайняя консультация:' | translate }}
                <span>
                        {{ patient.last_consultation.start_time_end_consultation | date: 'dd.MM.YYYY HH:mm' }}
                      </span>
              </ng-container>

              <ng-template
                #noConsultations>{{ 'Нет пройденных консультаций' | translate }}
              </ng-template>
            </div>

            <div class="actions">
              <button
                *ngIf="isDialogueModal"
                class="btn btn-primary"
                (click)="startDialogue(patient)">
                {{ 'Написать' | translate }}
              </button>

              <button
                *ngIf="!isDialogueModal"
                class="btn btn-primary"
                (click)="invitePatient(patient)">{{ 'Пригласить' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <mat-paginator
        *ngIf="total > 8 && !isLoading"
        [length]="total"
        [pageSize]="params.perPage"
        [pageIndex]="params.page - 1"
        [pageSizeOptions]="[4, 8, 16]"
        (page)="onPageChange($event)"
      ></mat-paginator>
    </div>
  </ng-container>

  <ng-template #empty>
    <app-empty-result
      *ngIf="!isLoading"
      [title]="isSearch ? 'По данному запросу ничего не найдено!' : 'У Вас пока нет своих пациентов!'">
    </app-empty-result>
  </ng-template>

  <app-spinner [isLoading]="isLoading"></app-spinner>
</div>
