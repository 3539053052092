import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {
  GetWorkPointsFilter,
  PostChangeStatusFilter
} from '../interfaces/work-points/work-points-filter.interface';
import {ApiWorkPoints, WorkPoints} from '../interfaces/work-points/work-points.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {ApiResponse} from '@core/interfaces/response.interface';

@Injectable()
export class WorkPointsService {
  updateInactiveWorkPoints$ = new BehaviorSubject<boolean | null>(null);
  private doctors4uaUrl = `${environment.doctors4uaUrl}/points`;

  constructor(private http: HttpClient) {}

  getWorkPoints(filter: GetWorkPointsFilter): Observable<WorkPoints[]> {
    const params = getObjectUrlParams(filter);

    return this.http.get<ApiResponse<ApiWorkPoints[]>>(this.doctors4uaUrl, {params})
      .pipe(
        map((result: ApiResponse<ApiWorkPoints[]>) => {
          return result.data.map((item: ApiWorkPoints) => {
            return {
              id: item.id,
              ...item.attributes
            };
          });
        })
      );
  }

  changeStatus(params: PostChangeStatusFilter): Observable<void> {
    return this.http.post<void>(`${this.doctors4uaUrl}/change-status`, params);
  }
}
