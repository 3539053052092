import {Component, Inject, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {finalize} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {Patient} from '../../../core/interfaces/patient.interface';
import {
  GetPatientsListParamsInterface
} from '../../../dashboard/patients/interfaces/patients-params.interface';
import {PatientsService} from '../../../dashboard/patients/patients.service';
import {DeserializedJsonInterface} from '../../../core/interfaces/jsona.interface';
import {ProfileService} from '@profile/profile.service';

@UntilDestroy()

@Component({
  selector: 'app-modal-find-user',
  templateUrl: './modal-find-user.component.html',
  styleUrls: ['./modal-find-user.component.scss']
})
export class ModalFindUserComponent implements OnInit {
  patients: Patient[] = [];
  isDialogueModal: boolean;

  emptyTitle = 'У Вас пока нет своих пациентов!';

  isSearch = false;

  params: Partial<GetPatientsListParamsInterface> = {
    page: 1,
    perPage: 4,
    field: 'updated_at',
    direction: 'DESC'
  };

  total = 0;

  firstRequest = true;

  isLoading: boolean;

  constructor(
    private patientsService: PatientsService,
    private profileService: ProfileService,
    private dialogRef: MatDialogRef<ModalFindUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  ngOnInit(): void {
    this.isDialogueModal = this.data.isDialogueModal;
    if (this.isDialogueModal) {
      this.params.relation_last_consultation = true;
      this.params.with_relation_discussion = true;

      this.getMyPatients();
      return;
    }

    this.getAllPatients();
  }

  onSearch(name: string): void {
    this.isDialogueModal ? this.params.name_all = name : this.params.surname = name;
    this.params.page = 1;
    this.isDialogueModal ? this.getMyPatients(false) : this.getAllPatients(false);

    this.isSearch = true;
  }

  onPageChange(event: PageEvent): void {
    this.params.page = event.pageIndex + 1;
    this.params.perPage = event.pageSize;

    if (this.isDialogueModal) {
      this.getMyPatients(false);
      return;
    }

    this.getAllPatients(false);
  }

  startDialogue(patient: Patient): void {
    this.dialogRef.close({patient_id: patient.id});
  }

  invitePatient(patient: Patient): void {
    this.dialogRef.close(patient);
  }

  private getMyPatients(firstRequest = true): void {
    this.isLoading = true;
    this.patientsService.getPatientsList(this.params)
      .pipe(
        finalize(() => this.isLoading = false),
        untilDestroyed(this)
      )
      .subscribe((result: DeserializedJsonInterface<Patient[]>) => {
        this.patients = result.data;
        this.total = result.meta.total;
        this.firstRequest = firstRequest;
      });
  }

  private getAllPatients(firstRequest = true): void {
    this.isLoading = true;
    this.patientsService.getAllPatientsList(this.params)
      .pipe(
        finalize(() => this.isLoading = false),
        untilDestroyed(this)
      )
      .subscribe(res => {
        this.total = res.meta.total;
        this.patients = res.data;
        this.firstRequest = firstRequest;
      });
  }
}
