import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormGroup, Validators} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ConsultationsService} from '@consultations/consultations.service';
import {
  MedicationsFormsService
} from '@shared-modules/medications/services/medications-forms.service';
import {
  AppointmentFormService
} from '@shared-modules/medications/services/appointment-form.service';
import {
  maxDiagnosisLength,
  maxRecommendationLength
} from '@shared-modules/medications/constants/appointment-form.const';
import {
  RecommendationFormDataInterface
} from '../../interfaces/apointment-form.interface';

@UntilDestroy()
@Component({
  selector: 'app-modal-appointment',
  templateUrl: './modal-appointment.component.html',
  styleUrls: ['./modal-appointment.component.scss']
})
export class ModalAppointmentComponent implements OnInit {
  maxRecommendationLength = maxRecommendationLength;
  maxDiagnosisLength = maxDiagnosisLength;
  recommendationFormData: RecommendationFormDataInterface;
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ModalAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { consultationId: number, isResultPage: boolean },
    private consultationsService: ConsultationsService,
    private medicationsFormsService: MedicationsFormsService,
    private appointmentFormService: AppointmentFormService
  ) {
  }

  ngOnInit(): void {
    this.form = this.medicationsFormsService.appointmentForm();
    this.getFormValueFromCoockie();

    if (this.data.isResultPage) {
      this.form.get('diagnosis').setValidators(Validators.required);
    }
  }

  closeModal(): void {
    this.dialogRef.close(!!this.form.get('diagnosis').value.trim().length);
  }

  saveRecommendation(needClose = true): void {
    if (this.data.isResultPage && !this.form.value.diagnosis.length) {
      this.form.markAllAsTouched();
      return;
    }

    this.appointmentFormService.updateCookie(this.form.value, this.data.consultationId);

    if (needClose) {
      this.dialogRef.close(!!this.form.value.diagnosis.trim().length);
    }
  }

  private getFormValueFromCoockie(): void {
    this.recommendationFormData = this.appointmentFormService.getValueFromCookie(this.data.consultationId);

    this.form.patchValue(this.recommendationFormData);
  }
}
