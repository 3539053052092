<div class="times">
  <div class="times_field">
    <div class="label" [class.absolute]="selectedTimes.length">
      {{
        selectedType === medicationTypesEnum.Procedure
          ? ('Время выполнения' | translate)
          : ('Время приема' | translate)
      }} *
    </div>

    <div class="times_item" *ngFor="let time of selectedTimes; let index = index">
      {{ time }}
      <mat-icon (click)="remove(index)">close</mat-icon>
    </div>

    <input
      [format]="24"
      [ngxTimepicker]="toggleTimepicker" readonly
      hidden
    >
    <ngx-material-timepicker-toggle
      [disabled]='selectedTimes.length >= maxCount'
      [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker
      #toggleTimepicker
      [cancelBtnTmpl]="cancelBtn"
      [confirmBtnTmpl]="confirmBtn"
      [defaultTime]="'00:00'"
      (timeSet)="selectTime($event)"
    >
    </ngx-material-timepicker>

    <ng-template #cancelBtn>
      <button
        type="button"
        class="btn btn-secondary"
      >
        {{'Закрыть' | translate}}
      </button>
    </ng-template>
    <ng-template #confirmBtn>
      <button
        type="button"
        class="btn btn-primary"
      >
        {{'Добавить' | translate}}
      </button>
    </ng-template>
  </div>
</div>
