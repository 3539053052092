import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {ClinicSettingsService} from '@core/services/clinic-settings.service';

@UntilDestroy()

@Component({
  selector: 'app-link-social',
  templateUrl: './link-social.component.html',
  styleUrls: ['./link-social.component.scss']
})

export class LinkSocialComponent implements OnInit {
  telegram = '';

  constructor(private clinicSettings: ClinicSettingsService) {
  }

  ngOnInit(): void {
    this.clinicSettings.clinicSettings$
      .pipe(untilDestroyed(this))
      .subscribe(settings => this.telegram = settings?.telegramLink);
  }
}
