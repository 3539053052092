import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

import {ModalHeaderComponent} from './modal-header.component';
import {TranslateModule} from "@core/pipes/translate/translate.module";

@NgModule({
  declarations: [ModalHeaderComponent],
    imports: [CommonModule, MatIconModule, TranslateModule],
  exports: [ModalHeaderComponent]
})
export class ModalHeaderModule {
}
