import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SpecializationNamesComponent} from './specialization-names.component';
import {SpecializationNamesPipe} from './specialization-names.pipe';
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    SpecializationNamesComponent,
    SpecializationNamesPipe
  ],
    imports: [
        CommonModule,
        TranslateModule
    ],
  exports: [
    SpecializationNamesComponent
  ]
})
export class SpecializationNamesModule {
}
