<div class="chat-error text-center">
  <p class="message">
    Извините, у пациента возникли проблемы с интернетом
    <br />
    при подключении к консультации.
  </p>

  <div class="btn-to-home">
    <button [routerLink]="['/chat', chatId]" class="btn btn-secondary">
      Попробовать еще раз
    </button>
  </div>
</div>
