<div class="modal-wrap">
  <app-modal-header
    [title]="'Добавить лечение'"
    [cross]="true"
    (close)="closeModal()"
  >
  </app-modal-header>

  <div class="modal_content">
    <form class="medications" [formGroup]="form">
      <div
        formArrayName="medications"
        *ngFor="
      let medication of medications?.controls;
      let i = index;
      ">
        <div class="medications_wrap" [formGroupName]="i">
          <div class="medications_delete">
            <div
              class="medications_icon"
              (click)="removeMedication(i)"
            >
              <mat-icon>delete</mat-icon>
            </div>
          </div>

          <div class="medications_row no_margin">
            <div class="type">
              <mat-radio-group
                class="type_list"
                formControlName="type"
                (change)="changeType($event, i)"
              >
                <mat-radio-button
                  class="type_item"
                  [value]="medicationTypesEnum.Medicine">
                  {{ 'Лекарства' | translate }}
                </mat-radio-button>

                <mat-radio-button
                  class="type_item"
                  [value]="medicationTypesEnum.Procedure">
                  {{ 'Процедура' | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div class="medications_row">
            <div class="medications_item">
              <mat-form-field appearance="outline" class="form_field">
                <mat-label>{{ 'Название' | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'Название' | translate"
                  formControlName="name"
                  required
                />
                <mat-error>
                  <app-validator-message [field]="medication?.get('name')"></app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>

            <div
              class="medications_item"
              *ngIf="medication.get('type').value === medicationTypesEnum.Medicine"
            >
              <mat-form-field appearance="outline" class="form_field">
                <mat-label>{{ 'Дозировка' | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'Дозировка' | translate"
                  formControlName="dose"
                  required
                />
                <mat-error>
                  <app-validator-message [field]="medication?.get('dose')"></app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="medications_item">
              <mat-form-field appearance="outline">
                <mat-label>
                  {{
                    medication?.get('type').value === medicationTypesEnum.Procedure
                      ? ('Регулярность выполнения' | translate) + '*'
                      : ('Регулярность приема' | translate) + '*'
                  }}
                </mat-label>

                <mat-select
                  [placeholder]="medication?.get('type')?.value === medicationTypesEnum.Procedure  ? ('Регулярність виконання' | translate) + '*': ('Регулярність прийому' | translate) + '*'"
                  formControlName="frequency"
                >
                  <mat-option *ngFor="let item of frequencyListConst" [value]="item.frequency">
                    {{ item.title | translate }}
                  </mat-option>
                </mat-select>

                <mat-error>
                  <app-validator-message
                    [field]="medication?.get('frequency')"
                  >
                  </app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="medications_row">
            <div class="medications_item">
              <mat-form-field appearance="outline" class="medications_range">
                <mat-label>{{ 'Длительность лечения' | translate }}</mat-label>
                <mat-date-range-input
                  [rangePicker]="picker"
                  [min]="medication.get('id').value ? setMinDate(medication.get('from').value) : minDate"
                  [max]="maxDate"
                  required
                >
                  <input
                    (click)="picker.open()"
                    matStartDate
                    formControlName="from"
                    readonly
                  >

                  <input
                    (click)="picker.open()"
                    matEndDate
                    formControlName="to"
                    readonly
                  >
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                >
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>

                <mat-error>
                  <app-validator-message [field]="medication?.get('from')">
                  </app-validator-message>
                  <app-validator-message [field]="medication?.get('to')"></app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="medications_item times">
              <app-time-field
                [selectedType]="medication?.get('type')?.value"
                [times]="medication?.get('times')?.value"
                (setTimes)="setTimes($event, i)"
              >
              </app-time-field>
            </div>

            <div class="medications_item">
              <mat-form-field appearance="outline" class="form_field">
                <mat-label>{{ 'Допустимое отклонение (часы)' | translate }}</mat-label>
                <input
                  matInput
                  type="number"
                  min="0"
                  max="24"
                  appNumberField
                  [maxValue]="24"
                  [placeholder]="'Допустимое отклонение (часы)' | translate"
                  formControlName="availableError"
                  required
                />
                <mat-error>
                  <app-validator-message
                    [field]="medication?.get('availableError')"></app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <ng-container
            *ngIf="medication?.get('type')?.value === medicationTypesEnum.Medicine; else procedure">
            <div class="medications_row">
              <div class="medications_item">
                <mat-form-field appearance="outline">
                  <mat-label>
                    {{ 'Тип введения препарата' | translate }}
                  </mat-label>

                  <mat-select
                    [placeholder]="'Тип введения препарата' | translate"
                    formControlName="administrationRoute"
                    (selectionChange)="checkAdministrationRoute($event, i)"
                  >
                    <mat-option *ngFor="let item of administrationRouteListConst"
                                [value]="item.type">
                      {{ item.label | translate }}
                    </mat-option>
                  </mat-select>

                  <mat-error>
                    <app-validator-message
                      [field]="medication?.get('administrationRoute')"
                    >
                    </app-validator-message>
                  </mat-error>
                </mat-form-field>
              </div>

              <ng-container *ngIf="medication?.get('administrationRoute')?.value === otherRoute">
                <div class="medications_item">
                  <mat-form-field appearance="outline" class="form_field">
                    <mat-label>{{ 'Описание Другое' | translate }}</mat-label>
                    <input
                      matInput
                      [placeholder]="'Описание Другое' | translate"
                      formControlName="administrationRouteText"
                    />
                    <mat-error>
                      <app-validator-message
                        [field]="medication?.get('administrationRouteText')"></app-validator-message>
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

              <div class="medications_item">
                <mat-form-field appearance="outline" class="form_field">
                  <mat-label>{{ 'Примечания' | translate }}</mat-label>
                  <input
                    matInput
                    [placeholder]="'Примечания' | translate"
                    formControlName="note"
                  />
                  <mat-error>
                    <app-validator-message
                      [field]="medication?.get('note')"></app-validator-message>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </ng-container>

          <ng-template #procedure>
            <div class="medications_row no_margin">
              <mat-form-field appearance="outline" class="form_field">
                <mat-label>{{ 'Примечания' | translate }}</mat-label>
                <input
                  matInput
                  [placeholder]="'Примечания' | translate"
                  formControlName="note"
                />
                <mat-error>
                  <app-validator-message [field]="medication?.get('note')"></app-validator-message>
                </mat-error>
              </mat-form-field>
            </div>
          </ng-template>

          <div class="medications_actions">
            <button
              type="button"
              class="btn btn-primary"
              (click)="saveMedicationForm(i)"
              [disabled]="medication.invalid"
            >
              {{
                medication.get('id').value
                  ? ('Обновить' | translate)
                  : ('Сохранить' | translate)
              }}
            </button>
          </div>
        </div>
      </div>
    </form>

    <div class="modal_actions">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="addMedication()"
        [disabled]="disableAdd"
      >
        <mat-icon>add</mat-icon>
        {{ 'Добавить' | translate }}
      </button>
    </div>
  </div>
</div>
