import {CalendarNativeDateFormatter, DateFormatterParams} from 'angular-calendar';
import {Injectable} from '@angular/core';

@Injectable()
export class CalendarFormatterClass extends CalendarNativeDateFormatter {
  weekViewHour({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }

  dayViewHour({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
    }).format(date);
  }
}
