import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';

import {ModalDeleteProfileComponent} from './modal-delete-profile.component';
import {ModalHeaderModule} from '../modal-header/modal-header.module';
import {TranslateModule} from '../../../core/pipes/translate/translate.module';


@NgModule({
  declarations: [ModalDeleteProfileComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    ModalHeaderModule,
    TranslateModule
  ],
  exports: [ModalDeleteProfileComponent],
})
export class ModalDeleteProfileModule {
}
