import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';

import {unsubscribe} from '@core/utils/unsubscriber';

@Component({
  selector: 'app-patients-context-search',
  templateUrl: './patients-context-search.component.html',
  styleUrls: ['./patients-context-search.component.scss']
})
export class PatientsContextSearchComponent implements OnInit, OnDestroy {
  @Input() isMyPatientsOnly = false;

  @Output() search: EventEmitter<any> = new EventEmitter();

  name = new FormControl('', [Validators.minLength(3), Validators.maxLength(50)]);

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscribeForm();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private subscribeForm(): void {
    const valueChangesSub = this.name.valueChanges
      .pipe(debounceTime(600))
      .subscribe((value) => {
        if (this.name.invalid) {
          return this.name.markAllAsTouched();
        }

        this.search.emit(value.length ? value : '');
      });
    this.subscriptions.push(valueChangesSub);
  }

}
