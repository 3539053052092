<div class="header">
  <div class="header_container" [class.wizard]="!showIcons">
    <app-logo [isHeaderShortened]="isHeaderShortened"></app-logo>

    <ng-container *ngIf="showIcons; else wizardData">
      <div class="header_nav" *ngIf="!isHeaderShortened">
        <app-link-consultations></app-link-consultations>

        <app-link-schedule></app-link-schedule>

        <app-link-my-patients></app-link-my-patients>

        <app-link-dialogues></app-link-dialogues>
      </div>

      <div class="header_info">
        <app-language-select></app-language-select>

        <app-notifications-list></app-notifications-list>

        <app-profile [disableClick]="isHeaderShortened"></app-profile>

        <div *ngIf="!windowSizeConfig.isSmallDesktop && !isHeaderShortened" class="sidenav-toggle">
          <button mat-icon-button (click)="toggleSidenav.emit()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #wizardData>
      <div class="wizard-data">
        <span>
          {{ '2 простых шага, чтобы начать' | translate }}
        </span>
      </div>
    </ng-template>
  </div>
</div>
