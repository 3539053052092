import {AdministrationRouteEnum} from '../enums/administration-route.enum';

export const administrationRouteConst = {
  [AdministrationRouteEnum.Inhalation]: 'Ингаляционное',
  [AdministrationRouteEnum.Oral]: 'Пероральное',
  [AdministrationRouteEnum.Intravascular]: 'Внутрисосудистое',
  [AdministrationRouteEnum.Intramuscular]: 'Внутримышечное',
  [AdministrationRouteEnum.Other]: 'Другое',
};

export const administrationRouteListConst = [
  {
    type: AdministrationRouteEnum.Inhalation,
    label: administrationRouteConst[AdministrationRouteEnum.Inhalation]
  },
  {
    type: AdministrationRouteEnum.Oral,
    label: administrationRouteConst[AdministrationRouteEnum.Oral]
  },
  {
    type: AdministrationRouteEnum.Intravascular,
    label: administrationRouteConst[AdministrationRouteEnum.Intravascular]
  },
  {
    type: AdministrationRouteEnum.Intramuscular,
    label: administrationRouteConst[AdministrationRouteEnum.Intramuscular]
  },
  {
    type: AdministrationRouteEnum.Other,
    label: administrationRouteConst[AdministrationRouteEnum.Other]
  },
];
