import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {
  DirectionsFieldsLabelsConst
} from '@shared-modules/medications/constants/directions-fields-labels.const';

@UntilDestroy()

@Component({
  selector: 'app-direction-field',
  templateUrl: './direction-field.component.html',
  styleUrls: ['./direction-field.component.scss']
})
export class DirectionFieldComponent implements OnInit {
  @Input() type: number;
  @Input() value!: string;

  @Output() fieldValue = new EventEmitter();

  fieldType: any;
  direction: FormControl = new FormControl('');

  constructor() {
  }

  ngOnInit(): void {
    this.direction.patchValue(this.value ? this.value : '');
    this.fieldType = DirectionsFieldsLabelsConst.find(el => el.type === this.type);
    this.watchField();
  }

  private watchField(): void {
    this.direction.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.fieldValue.emit({text: value, fieldType: this.type});
      });
  }
}
