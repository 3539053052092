import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatRadioChange} from '@angular/material/radio';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {filter, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MedicationInterface} from '@shared-modules/medications/interfaces/medication.interface';
import {MedicationsService} from '@shared-modules/medications/services/medications.service';
import {
  MedicationsFormsService
} from '@shared-modules/medications/services/medications-forms.service';
import {FrequencyListConst} from '@shared-modules/medications/constants/frequency-list.const';
import {MedicationTypesEnum} from '@shared-modules/medications/enums/medication-types.enum';
import {ModalInfoService} from '@core/services/modal-info.service';
import {ProfileService} from '@profile/profile.service';
import {ModalConfirmComponent} from '@shared-modules/modals/modal-confirm/modal-confirm.component';
import {
  administrationRouteListConst
} from '@shared-modules/medications/constants/administration-route.const';
import {AdministrationRouteEnum} from '@shared-modules/medications/enums/administration-route.enum';

@UntilDestroy()
@Component({
  selector: 'app-modal-medication',
  templateUrl: './modal-medication.component.html',
  styleUrls: ['./modal-medication.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class ModalMedicationComponent implements OnInit {
  form: FormGroup;
  medications: FormArray;
  frequencyListConst = FrequencyListConst;
  administrationRouteListConst = administrationRouteListConst;
  otherRoute = AdministrationRouteEnum.Other;
  medicationTypesEnum = MedicationTypesEnum;

  minDate = moment().startOf('day');
  maxDate = moment().add(6, 'month');

  disableAdd = true;

  constructor(
    private dialogRef: MatDialogRef<ModalMedicationComponent>,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private medicationsService: MedicationsService,
    private dateAdapter: DateAdapter<Date>,
    private profileService: ProfileService,
    private medicationsFormsService: MedicationsFormsService,
    private modalInfoService: ModalInfoService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: {
      consultationId: number;
      medications: MedicationInterface[];
    }
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.setLocale();

    if (this.data.medications.length) {
      this.patchMedications(this.data.medications);
      this.disableAdd = false;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  setTimes(event: string[], i: number): void {
    this.medications.controls[i].get('times').patchValue(event);
  }

  removeMedication(i: number): void {
    const type = this.medications.controls[i].get('type').value;

    if (!this.medications.controls[i].get('id').value && this.medications.length < 2) {
      return;
    }

    const dialogRef = this.dialog.open(ModalConfirmComponent, {
      data: {
        title: 'Внимание!',
        message: `Вы действительно хотите удалить ${type === MedicationTypesEnum.Medicine ? 'лечение' : 'процедуру'}?`
      },
      panelClass: ['primary-modal', 'modal-sm'],
      autoFocus: false
    });

    dialogRef.afterClosed()
      .pipe(
        filter(r => r),
        mergeMap(() => {
          if (!this.medications.controls[i].get('id').value) {
            return of(null);
          }

          return this.medicationsService.deleteMedication(this.medications.controls[i].get('id').value);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.medications.removeAt(i);
        this.disableAdd = false;

        if (!this.medications.length) {
          this.medications.push(this.medicationsFormsService.medicationForm());
          this.disableAdd = true;
        }
      });
  }

  addMedication(): void {
    this.disableAdd = true;
    this.medications.push(this.medicationsFormsService.medicationForm());
  }

  checkAdministrationRoute($event: MatSelectChange, index: number): void {
    if ($event.value === this.otherRoute) {
      this.medications.controls[index].get('administrationRouteText')
        .setValidators([Validators.required, Validators.maxLength(100)]);
      this.medications.controls[index].get('administrationRouteText')
        .updateValueAndValidity({emitEvent: false});
      return;
    }

    this.clearValidators('administrationRouteText', index);
  }

  changeType(event: MatRadioChange, index: number): void {
    if (event.value === MedicationTypesEnum.Medicine) {
      this.medications.controls[index].get('dose')
        .setValidators([Validators.required, Validators.maxLength(100)]);
      return;
    }

    this.clearValidators('dose', index);
    this.clearValidators('administrationRoute', index);
    this.clearValidators('administrationRouteText', index);
  }

  saveMedicationForm(i: number): void {
    if (this.medications.controls[i].invalid) {
      this.medications.controls[i].markAllAsTouched();
      return;
    }

    this.medications.controls[i].disable();

    const value = this.medications.controls[i].value;
    const formValue = {
      ...value,
      from: moment(value.from).unix(),
      to: moment(value.to).endOf('day').unix(),
      appointmentId: this.data.consultationId
    };

    this.medicationsService.manageMedication(formValue, formValue.id)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.disableAdd = false;
        const medication = this.convertMedication(res);
        this.medications.controls[i].patchValue(medication);
        this.medications.controls[i].enable();

        this.openSnackBar(medication.type);
      });
  }

  setMinDate(date: any): Moment {
    return moment(date).startOf('day').unix() < moment().startOf('day').unix()
      ? moment(date).startOf('day')
      : moment().startOf('day');
  }

  private initForm(): void {
    this.form = this.fb.group({
      medications: this.fb.array(
        !this.data.medications.length
          ? [this.medicationsFormsService.medicationForm()]
          : []
      )
    });

    this.medications = this.form.get('medications') as FormArray;
  }

  private convertMedication(medication: MedicationInterface): MedicationInterface {
    return {
      ...medication,
      from: moment.unix(medication.from),
      to: moment.unix(medication.to)
    };
  }

  private patchMedications(medications: MedicationInterface[]): void {
    medications.forEach(medication => {
      const item = this.convertMedication(medication);
      this.medications.push(this.medicationsFormsService.medicationForm(item));
    });
  }

  private setLocale(): void {
    this.profileService.currentLang$
      .pipe(untilDestroyed(this))
      .subscribe(res => this.dateAdapter.setLocale(res.locale));
  }

  private clearValidators(field: string, index: number): void {
    this.medications.controls[index].get(field).clearValidators();
    this.medications.controls[index].get(field).patchValue(null);
    this.medications.controls[index].get(field).setErrors(null);
  }

  private openSnackBar(type): void {
    const message = this.translateService
      .instant(
        type === MedicationTypesEnum.Medicine
          ? 'Лечение было добавлено'
          : 'Процедура была добавлена'
      );

    this.snackBar.open(
      message,
      'X',
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      },
    );
  }
}
