import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

import {environment} from '../../../environments/environment';
import {localToken} from '../../app.constants';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {Crypto} from '@core/classes/crypto.class';
import {JsonaService} from '@core/services/jsona.service';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {GetProfileParamsInterface} from '@core/interfaces/profile-params.interface';
import {SafeImageService} from '@shared-modules/safe-image/safe-image.service';
import {LocalesConst} from '@core/constants/locales.const';
import {Clinic} from '../../clinics/interfaces/clinic.interface';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile$ = new BehaviorSubject<Doctor>(null);
  isShowStep$ = new BehaviorSubject<boolean>(false);
  currentLang$ = new BehaviorSubject<any | null>(null);
  activeClinic$ = new BehaviorSubject<Clinic | null>(null);
  hasPayment$ = new BehaviorSubject<boolean | null>(null);

  profileIsActive$ = new BehaviorSubject<boolean | null>(null);

  langLocales = LocalesConst;

  crypto: Crypto = new Crypto();

  private getProfileParams: Partial<GetProfileParamsInterface> = {
    with_relation_additional_info: true,
    relation_active_clinic: true,
    with_relation_educations: true,
    with_relation_jobs: true,
    with_relation_clinic_addresses: 1
  };

  private doctors4uaUrl = environment.doctors4uaUrl;
  private doctors4uaUrlV2 = environment.doctors4uaUrlV2;

  constructor(
    private http: HttpClient,
    private router: Router,
    private jsonaService: JsonaService,
    private safeImageService: SafeImageService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  loadProfile(): Observable<Doctor> {
    return this.getProfile(this.getProfileParams)
      .pipe(
        tap((result: Doctor) => {
          this.profile$.next(result);
          this.activeClinic$.next(result.active_clinic || null);
          this.currentLang$.next(this.langLocales.find(el => el.code === result.lang));
          this.isShowStep$.next(!!result.surname);
          this.document.body.classList.add('authorized');
        })
      );
  }

  getProfile(filter: Partial<GetProfileParamsInterface>): Observable<Doctor> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.doctors4uaUrlV2}/profile`, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Doctor>(result).data),
      );
  }

  update(
    data: FormData,
    filter: Partial<GetProfileParamsInterface> = this.getProfileParams
  ): Observable<Doctor> {
    const params = getObjectUrlParams(filter);

    return this.http.post(`${this.doctors4uaUrlV2}/profile`, data, {params})
      .pipe(
        map((result) => this.jsonaService.deserialize<Doctor>(result).data),
      );
  }

  getToken(): string {
    return localStorage.getItem(this.crypto.encrypt(localToken));
  }

  updateToken(res): void {
    if (res.headers) {
      const token = res.headers.get('authorization');

      if (token) {
        this.setTokenLocal(token);
      }
    }
  }

  setTokenLocal(token): void {
    localStorage.setItem(this.crypto.encrypt(localToken), token);
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem(this.crypto.encrypt(localToken));
  }

  logout(): void {
    localStorage.removeItem(this.crypto.encrypt(localToken));
    localStorage.removeItem('wizard');
    localStorage.removeItem('showNearest');
    this.profile$.next(null);
    this.safeImageService.clearImageStore();
    this.router.navigate(['/auth/login']);
    this.document.body.classList.remove('authorized');
  }

  changeActiveProfile(activate: number, remove: number): Observable<any> {
    return this.http
      .get(`${this.doctors4uaUrl}/doctors/change-active/${activate}/${remove}`)
      .pipe(map((res: any) => res));
  }

  patchLanguage(lang): Observable<any> {
    return this.http.patch(`${this.doctors4uaUrlV2}/profile`, {lang})
      .pipe(map(res => this.jsonaService.deserialize<Doctor>(res).data));
  }
}
