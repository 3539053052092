import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';
import {ProfileService} from '@profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {
  private doctors4uaUrl = `${environment.doctors4uaUrl}/file`;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private profileService: ProfileService
  ) {}

  buildImageByToken(name: string, storage: string, anamnesisId?: number): Observable<SafeUrl> {
    const params: any = {};

    if (storage !== 'local') {
      params.authorization = this.profileService.getToken().replace('Bearer ', '');
    }

    if (anamnesisId || anamnesisId === 0) {
      params.anamnes = anamnesisId;
    }

    return this.http
      .get(`${this.doctors4uaUrl}/${storage}/${name}`, {responseType: 'blob', params})
      .pipe(
        map((result: Blob) => {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
        })
      );
  }

  buildPdfByToken(fileName, storage = 'local', anamnesisId?: number) {
    const params: any = {};

    if (storage !== 'local') {
      params.authorization = this.profileService.getToken().replace('Bearer ', '');
    }

    if (anamnesisId) {
      params.anamnes = anamnesisId;
    }

    return this.http
      .get(`${this.doctors4uaUrl}/${storage}/${fileName}`, {responseType: 'blob', params})
      .pipe(
        map((res) => {
          return {
            secureUrl: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(res)),
            url: URL.createObjectURL(res),
            file: res
          };
        })
      );
  }
}
