import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {combineLatest} from "rxjs";

import {popStateListener} from '../../../helper';
import {AppLayoutConfigInterface} from './interfaces/app-layout-config.interface';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {WindowSizeService} from '@core/services/window-size.service';
import {prepareAppLayoutConfig} from '../../layouts/app-layout/utils/prepare-app-layout-config';
import {NearestConsultationService} from '@core/services/nearest-consultation.service';
import {NetworkStateTypesEnum} from '@shared-modules/network-state/network-state-types.enum';
import {WaitingAreaService} from '../../../dashboard/waiting-area/services/waiting-area.service';
import {NetworkStateService} from '@shared-modules/network-state/network-state.service';
import {ProfileService} from '@profile/profile.service';
import {Clinic} from '../../../clinics/interfaces/clinic.interface';
import {ClinicIdsConst} from '@core/constants/clinic-ids.const';
import {ClinicSettingsService} from "@core/services/clinic-settings.service";
import {CentrifugoSocketService} from "@core/services/centrifugo-socket.service";
import {CentrifugoSocketChannelsEnum} from "@core/enums/centrifugo-socket-channels.enum";
import {logSocketSubInfo, logWaitingAreaSubInfo} from "@core/utils/socket-logger";
import {MessagesCountsService} from "@core/services/messages-counts.service";

@UntilDestroy()

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  providers: [WaitingAreaService]
})
export class AppLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  showNearestConsultation$ = this.nearestConsultationService.showNearestConsultation$;

  isWaitingAreaActive = false;
  socket: any;
  socketClient: any;

  activeClinic: Clinic;
  waitingAreaChannel: string;

  hideElementsByRoute = false;
  isResultPage = false;
  appLayoutConfig: AppLayoutConfigInterface;

  windowSizeConfig: WindowSizeConfigInterface;

  private audio: any;
  private title = 'Новый пациент в очереди';
  private titleTimerEmpty;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private windowSizeService: WindowSizeService,
    private nearestConsultationService: NearestConsultationService,
    private translateService: TranslateService,
    private titleService: Title,
    private profileService: ProfileService,
    private waitingAreaService: WaitingAreaService,
    private networkStateService: NetworkStateService,
    private clinicSettingsService: ClinicSettingsService,
    private centrifugoSocketService: CentrifugoSocketService,
    private messagesCountsService: MessagesCountsService
  ) {
    this.getCurrentRoute();
    this.handleWindowSizeConfig();
  }

  ngOnInit(): void {
    this.manageNearestConsultation();
    this.getActiveClinicAndSettings();
    this.centrifugoSocketService.socketInit();

    this.watchNetworkState();
    this.setPaymentStatus();

    this.getDoctorStatus();

    this.messagesCountsService.getUnreadMessages()
      .pipe(untilDestroyed(this))
      .subscribe();

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.clearTimers();
      }
    });
  }

  ngAfterViewInit(): void {
    window.removeEventListener('popstate', popStateListener);
  }

  ngOnDestroy(): void {
    this.centrifugoSocketService.socketDisconnect();

    this.clearTimers();
  }

  private handleWindowSizeConfig(): void {
    this.windowSizeService.windowSizeConfig$
      .pipe(untilDestroyed(this))
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => {
        this.windowSizeConfig = windowSizeConfig;
      });
  }

  private manageNearestConsultation(): void {
    const localValue = this.nearestConsultationService.getLocalSettings();
    if (!localValue) {
      this.nearestConsultationService.setLocalSetting(true);
      return;
    }

    this.nearestConsultationService.setLocalSetting(localValue === 'true');
  }

  private getCurrentRoute(): void {
    this.router.events
      .pipe(
        filter((e: NavigationEnd) => e instanceof NavigationEnd),
        map((navigation) => {
          this.hideElementsByRoute = navigation.url.includes('/chat');
          this.isResultPage = navigation.url.includes('/consultation-result');

          return this.route.snapshot
        }),
        map((activatedRoute) => activatedRoute.firstChild),
        untilDestroyed(this)
      )
      .subscribe((activatedRoute) => {
        this.appLayoutConfig = prepareAppLayoutConfig(activatedRoute.data);
      });
  }

  private getDoctorStatus(): void {
    combineLatest([
      this.centrifugoSocketService.socketConnectionSuccess$.pipe(filter((r) => r)),
      this.profileService.profileIsActive$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([socket, status]) => {
        this.socketClient = socket;
        this.isWaitingAreaActive = status;

        status
          ? this.subscribeToWaitingArea()
          : this.unsubscribeFromWaitingArea();
      });
  }

  private subscribeToWaitingArea(): void {
    this.socket = this.createSubscriptionToWaitingArea();

    this.socket
      .on('subscribed', (ctx) => {
        logSocketSubInfo(ctx.channel);
      })
      .on('unsubscribed', (ctx) => {
        logSocketSubInfo(`${ctx.code}: ${ctx.reason}`);
      })
      .on('publication', (ctx) => {
        this.onMessageReceived(ctx.data);
      })
      .subscribe();
  }

  private unsubscribeFromWaitingArea(): void {
    this.socket?.unsubscribe();
    this.socket?.removeAllListeners();
  }

  private onMessageReceived(message): void {
    logWaitingAreaSubInfo(message);
    if (message.type === 'new_patient') {
      if (this.isWaitingAreaActive) {
        this.addNotification();
      }

      this.waitingAreaService.onWaitingAreaUpdate$.next(true);
      this.waitingAreaService.newQuickConsultation$.next(true);
    }

    if (message.type === 'patient_skip_consultation') {
      this.clearTimers();
      this.waitingAreaService.newQuickConsultation$.next(null);
      setTimeout(() => {
        this.waitingAreaService.onWaitingAreaUpdate$.next(true);
      }, 500);
    }

  }

  private addNotification(): void {
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }

    this.addSound();

    this.clearTimers();

    if (document.visibilityState === 'hidden') {
      this.setTitle();
    }
  }

  private addSound(): void {
    this.audio = new Audio();
    this.audio.src = '/assets/sounds/juntos.mp3';
    this.audio.play();
  }

  private setTitle(): void {
    this.translateService.get(this.title)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.titleService.setTitle(result);

        this.titleTimerEmpty = setInterval(() => {
          if (document.querySelector('title').innerText === result) {
            const title = ClinicIdsConst.find(el => el.id === this.activeClinic.id).title;
            return this.titleService.setTitle(title);
          }
          this.titleService.setTitle(result);
        }, 1000);
      });
  }

  private clearTimers(): void {
    clearInterval(this.titleTimerEmpty);
    this.titleService.setTitle((window as any).environments.clinicName);
    setTimeout(() => this.titleService.setTitle((window as any).environments.clinicName), 10);
  }

  private setPaymentStatus(): void {
    this.profileService.hasPayment$.next((window as any).environments.hasPayment);
  }

  private getActiveClinicAndSettings(): void {
    this.profileService.activeClinic$
      .pipe(
        mergeMap(clinic => {
          this.activeClinic = clinic;

          this.waitingAreaChannel = `${CentrifugoSocketChannelsEnum.WAITING_AREA}${this.activeClinic.id}.waitingArea`;

          const params = {
            perPage: 2,
            field: 'clinic_id',
            direction: 'ASC'
          };

          return this.clinicSettingsService.getList(params);
        }),
        untilDestroyed(this)
      )
      .subscribe(res => {
        const settings = res.find(el => el.settingData && Object.keys(el.settingData).length)?.settingData;

        if (settings) {
          this.clinicSettingsService.clinicSettings$.next(settings);
        }
      });
  }

  private watchNetworkState(): void {
    this.networkStateService.networkConnectionState$
      .pipe(
        filter(r => !!r),
        untilDestroyed(this)
      )
      .subscribe(res => {
        if (res === NetworkStateTypesEnum.Online) {
          this.centrifugoSocketService.socketInit();
          this.waitingAreaService.onWaitingAreaUpdate$.next(true);
          return;
        }

        this.centrifugoSocketService.socketDisconnect();
      });
  }

  private createSubscriptionToWaitingArea(): any {
    return this.socketClient.getSubscription(this.waitingAreaChannel) || this.socketClient.newSubscription(this.waitingAreaChannel, {data: {'success': true}});
  }
}
