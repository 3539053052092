<div class="chat-error text-center">
  <p class="message">
    К сожалению, у Вас возникли проблемы с интернетом
    <br />
    при подключении к консультации.
  </p>

  <p class="message">
    Пожалуйста, проверьте интернет соединение
    <br />
    и попробуйте присоединиться еще раз.
  </p>

  <div class="btn-to-home">
    <button
      [routerLink]="['/chat', chatId]"
      class="btn btn-secondary"
      [disabled]="!isActiveReconnectBtn"
    >
      Попробовать еще раз
    </button>
  </div>
</div>
