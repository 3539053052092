<div class="success_container card" [class.in_modal]="inModal">
  <h3 class="success_title">{{ 'Спасибо за вашу работу!' | translate }}</h3>

  <div class="success_desc">
    <p>
      {{
      'Результаты консультации были отправлены пациенту. Вы можете пересмотреть их в карточке консультации или в карточке пациента' | translate
      }}
    </p>
  </div>

  <div class="success_recommendation">
    <div class="recommendation_item">
      <div class="recommendation_title">
        {{ 'Жалобы' | translate }}:
      </div>
      <div class="recommendation_value">
        <p>{{ consultation?.appointment_result?.idea }}</p>
      </div>
    </div>
    <div class="recommendation_item">
      <div class="recommendation_title">
        {{ 'Диагноз' | translate }}:
      </div>
      <div class="recommendation_value">
        <p>{{ consultation?.appointment_result?.diagnosis }}</p>
      </div>
    </div>
    <div class="recommendation_item" *ngIf="consultation?.appointment_result?.recommendations">
      <div class="recommendation_title">
        {{ 'Общие рекомендации' | translate }}:
      </div>
      <div class="recommendation_value">
        <p>{{ consultation?.appointment_result?.recommendations }}</p>
      </div>
    </div>
    <div class="success_files">
      <div
        class="files_item"
        *ngIf="consultation?.consultationPdf?.treatmentStorage"
        (click)="previewFile(consultation?.consultationPdf?.treatmentStorage)"
      >
        <mat-icon>insert_drive_file</mat-icon>
        <span>{{ 'Лечение' | translate }}</span>
      </div>
      <div
        class="files_item"
        *ngIf="consultation?.consultationPdf?.directionStorage"
        (click)="previewFile(consultation?.consultationPdf?.directionStorage)"
      >
        <mat-icon>insert_drive_file</mat-icon>
        <span>{{ 'Направления' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="success_actions">
    <button class="btn btn-secondary" (click)="invitePatient()">
      {{ 'Пригласить повторно' | translate }}
    </button>
    <button class="btn btn-primary" (click)="manageSuccess()">
      {{ inModal ? ('Закрыть' | translate) : ('На главную' | translate) }}
    </button>
  </div>
</div>
