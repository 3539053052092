import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {
  hoursForErrors,
  maxDiagnosisLength,
  maxRecommendationLength
} from '../constants/appointment-form.const';


@Injectable({
  providedIn: 'root'
})
export class MedicationsFormsService {
  private maxDiagnosisLength = maxDiagnosisLength;
  private maxRecommendationLength = maxRecommendationLength;
  private maxHoursForErrors = hoursForErrors;

  constructor(
    private fb: FormBuilder
  ) {
  }

  appointmentForm(): FormGroup {
    return this.fb.group({
      idea: ['', [Validators.maxLength(maxDiagnosisLength)]],
      diagnosis: ['', [Validators.maxLength(maxDiagnosisLength)]],
      recommendation: ['', [Validators.maxLength(maxRecommendationLength)]]
    });
  }

  medicationForm(medication = null): FormGroup {
    return this.fb.group({
      id: [medication?.id || null],
      type: [medication?.type || 1, Validators.required],
      name: [medication?.name || '', Validators.required],
      dose: [medication?.dose || '', medication?.type === 1 ? [Validators.required, Validators.maxLength(100)] : []],
      frequency: [medication?.frequency || 1, [Validators.required, Validators.max(7)]],
      from: [medication?.from || '', Validators.required],
      to: [medication?.to || '', Validators.required],
      times: [medication?.times || [], Validators.required],
      availableError: [medication?.availableError || 0, [Validators.required, Validators.max(this.maxHoursForErrors)]],
      note: [medication?.note || '', Validators.maxLength(500)],
      administrationRoute: [medication?.administrationRoute || ''],
      administrationRouteText: [medication?.administrationRouteText || ''],
    });
  }
}
