import {environment} from "../../../environments/environment";

export function logSocketInfo(str): null | void {
  if (environment.loggerConfig.socketInfo) {
    return console.info('%cSocket', 'color: blue;', str);
  }

  return null;
}

export function logSocketSubInfo(str): null | void {
  if (environment.loggerConfig.socketInfo) {
    return console.info('%cSocket sub: ', 'color: green;', str);
  }

  return null;
}

export function logNotificationSubInfo(message): void {
  if (environment.loggerConfig.notificationInfo) {
    console.info('%cNotification message', 'color: CadetBlue;', message);
  }
}

export function logWaitingAreaSubInfo(message): void {
  if (environment.loggerConfig.waitingAreaInfo) {
    console.info('%cWaiting Area message', 'color: Olive;', message);
  }
}

export function logSocketError(frame): void {
  if (environment.loggerConfig.socketError) {
    console.error('Socket reported error code: ', frame.error.code);
    console.error('Additional details: ', frame.error.message);
  }
}

export function logOnlineChatSubInfo(message): void {
  if (environment.loggerConfig.onlineChatInfo) {
    console.info('%cOnline chat message', 'color: indigo;', message);
  }
}

export function logDialogueSubInfo(title: number, message: any): void {
  if (environment.loggerConfig.dialogueInfo) {
    console.info(`%cDialogue ${title}`, 'color: LightBlue;', message);
  }
}
