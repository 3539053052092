import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Subscription} from 'rxjs';

import {WindowSizeService} from '@core/services/window-size.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {WindowSizeConfigInterface} from '@core/interfaces/window-size-config.interface';
import {ProfileService} from "@profile/profile.service";
import {ClinicIdsEnum} from "@core/enums/clinic-ids.enum";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnDestroy {
  @Output() toggleSidenav: EventEmitter<null> = new EventEmitter();
  @Output() closeSidenav: EventEmitter<null> = new EventEmitter();

  activeClinic$ = this.profileService.activeClinic$;
  clinicIds = ClinicIdsEnum;
  windowSizeConfig: WindowSizeConfigInterface;

  private subscriptions: Subscription[] = [];

  constructor(
    private windowSizeService: WindowSizeService,
    private profileService: ProfileService
    ) {
    this.handleWindowSizeConfig();
  }

  ngOnDestroy(): void {
    this.closeSidenav.emit();
    unsubscribe(this.subscriptions);
  }

  private handleWindowSizeConfig(): void {
    const windowSizeSub = this.windowSizeService.windowSizeConfig$
      .subscribe((windowSizeConfig: WindowSizeConfigInterface) => this.windowSizeConfig = windowSizeConfig);
    this.subscriptions.push(windowSizeSub);
  }
}
