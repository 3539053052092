import {Component} from '@angular/core';

@Component({
  selector: 'app-link-personal-data',
  templateUrl: './link-personal-data.component.html',
  styleUrls: ['./link-personal-data.component.scss']
})
export class LinkPersonalDataComponent {
  termsOfUse = (window as any).environments.termsOfUseLink;

}
