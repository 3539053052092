<div class="ng-select-custom select-lang" *ngIf="profile?.surname">
  <ng-select
    class="language"
    [items]="languages"
    bindLabel="viewValue"
    [clearable]="false"
    [searchable]="false"
    (change)="manageProfile($event)"
    bindValue="language"
    [formControl]="lang">

    <ng-template ng-label-tmp let-item="item">
      {{item.shortName | translate}}
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-index="index">
      {{item.shortName}}
    </ng-template>
  </ng-select>
</div>
