<div class="heading">
 <app-language-select></app-language-select>
</div>

<mat-nav-list
  class="app-nav-list"
  (click)="toggleSidenav.emit()"
>

  <div class="wrapper-nav">
    <app-link-consultations></app-link-consultations>

    <app-link-schedule></app-link-schedule>

    <app-link-my-patients></app-link-my-patients>

    <app-link-dialogues></app-link-dialogues>
  </div>

  <div class="divider"></div>

  <div class="wrapper-info">
    <app-link-support></app-link-support>

    <app-link-social></app-link-social>

    <app-link-personal-data></app-link-personal-data>
  </div>
</mat-nav-list>

<div class="apps">
  <app-apps-links *ngIf="(activeClinic$ | async).id === clinicIds.doctors4ua"></app-apps-links>
</div>
