import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AnamnesisComponent} from './anamnesis.component';
import {SafeImageModule} from '../safe-image/safe-image.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {MaterialModule} from '../../shared/material/material.module';

@NgModule({
  declarations: [
    AnamnesisComponent,
  ],
  imports: [
    CommonModule,
    SafeImageModule,
    TranslateModule,
    MaterialModule
  ],
  exports: [
    AnamnesisComponent
  ],
})
export class AnamnesisModule {
}
