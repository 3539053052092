import {Component} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {MessagesCountsService} from "@core/services/messages-counts.service";

@Component({
  selector: 'app-link-dialogues',
  templateUrl: './link-dialogues.component.html',
  styleUrls: ['./link-dialogues.component.scss']
})
export class LinkDialoguesComponent {
  unreadMessagesCount$: BehaviorSubject<number> = this.messagesCountsService.unreadMessagesCount$;

  constructor(private messagesCountsService: MessagesCountsService) {
  }
}
