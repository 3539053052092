import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appNumberField]'
})
export class NumberFieldDirective {
  @Input() maxValue = 999;

  constructor(private _el: ElementRef) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(event.key) !== -1) {
      return;
    }
    const nativeEl = this._el.nativeElement;
    const keyValue = +event.key;
    if (isNaN(keyValue)) {
      event.preventDefault();
      return;
    }

    const hasSelection = nativeEl.selectionStart !== nativeEl.selectionEnd && nativeEl.selectionStart !== null;
    const newValue = hasSelection
      ? this.replaceSelection(nativeEl, event.key)
      : nativeEl.value + keyValue.toString();

    if (+newValue > this.maxValue || newValue.length > this.maxValue.toString().length) {
      event.preventDefault();
    }
  }

  private replaceSelection(input, key): string {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }
}
