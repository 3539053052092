import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {PatientsContextSearchService} from './patients-context-search.service';
import {
  PatientsContextSearchComponent
} from './patients-context-search.component';
import {ValidatorMessageModule} from '../validator-message/validator-message.module';
import {PreventEnterModule} from '@core/directives/prevent-enter/prevent-enter.module';
import {TranslateModule} from "@core/pipes/translate/translate.module";


@NgModule({
  declarations: [
    PatientsContextSearchComponent
  ],
    imports: [
        CommonModule,
        ValidatorMessageModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        PreventEnterModule,
        TranslateModule
    ],
  exports: [
    PatientsContextSearchComponent
  ],
  providers: [
    PatientsContextSearchService
  ]
})
export class PatientsContextSearchModule {
}
