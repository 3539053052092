import {ClinicIdsEnum} from '../enums/clinic-ids.enum';

export const ClinicIdsConst = [
  {
    id: ClinicIdsEnum.doctors4ua,
    title: 'Doctors4ua'
  },
  {
    id: ClinicIdsEnum.DKKids,
    title: 'DK Kids'
  },
  {
    id: ClinicIdsEnum.DentistOmnycare,
    title: 'Dentist Omnycare'
  },
  {
    id: ClinicIdsEnum.YourBaby,
    title: 'Your Baby'
  }
]
