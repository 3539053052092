import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConsultationResultComponent } from './modal-consultation-result.component';
import {ConsultationResultModule} from "../../../consultation-result/consultation-result.module";



@NgModule({
  declarations: [
    ModalConsultationResultComponent
  ],
    imports: [
        CommonModule,
        ConsultationResultModule
    ]
})
export class ModalConsultationResultModule { }
