<div class="modal_wrap">
  <span class="title">{{ 'Направления' | translate }}</span>

  <div class="dialog-content">
    <div class="directions">

      <!-- doctors -->
      <mat-tab-group
        class="directions_tabs"
        mat-align-tabs="start"
        animationDuration="0ms"
      >
        <mat-tab [label]="'Врачи' | translate">
          <app-direction-field
            [type]="directionsFieldsEnum.Doctors"
            [value]="consultation?.referralsSpecialists"
            (fieldValue)="setFieldValue($event)"
          >
          </app-direction-field>
        </mat-tab>

        <!-- analizes -->
        <mat-tab [label]="'Анализы' | translate">
          <app-direction-field
            [type]="directionsFieldsEnum.Analizes"
            [value]="consultation?.analyzes"
            (fieldValue)="setFieldValue($event)"
          >
          </app-direction-field>
        </mat-tab>

        <!-- examination -->
        <mat-tab [label]="'Обследования' | translate">
          <app-direction-field
            [type]="directionsFieldsEnum.Examination"
            [value]="consultation?.referralsExamination"
            (fieldValue)="setFieldValue($event)"
          >
          </app-direction-field>
        </mat-tab>
      </mat-tab-group>
    </div>


    <mat-dialog-actions>
      <button
        class="btn btn-secondary"
        (click)="closeModal()"
      >
        {{ 'Отменить' | translate }}
      </button>

      <button
        class="btn btn-primary"
        (click)="saveDirections()"
      >
        {{ 'Сохранить' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
