<div class="modal_wrap">
    <img class="modal-img-state" [src]="image | imagePath" alt="Doctors4UA"/>

  <app-modal-header [title]="title"></app-modal-header>

  <div class="dialog-content">
    <p class="text-center">{{ data.message | translate }}</p>

    <mat-dialog-actions *ngIf="data.showBtn || data.type === 'error'">
      <button class="btn btn-primary" [mat-dialog-close]="false">
        {{ (data.btnText | translate ) || ('Закрыть' | translate) }}
      </button>
    </mat-dialog-actions>
  </div>
</div>
