import {Component} from '@angular/core';

import {ProfileService} from '@profile/profile.service';
import {ClinicIdsEnum} from '@core/enums/clinic-ids.enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  activeClinic$ = this.profileService.activeClinic$;
  clinicIds = ClinicIdsEnum;

  constructor(private profileService: ProfileService) {
  }
}
