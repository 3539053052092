import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import {ProfileService} from '@profile/profile.service';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPaginatorIntl extends MatPaginatorIntl {
  outOfZero: string;
  outOf: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private profileService: ProfileService
  ) {
    super();

    this.setPaginationsText();

    // @ts-ignore
    this.getRangeLabel = (page, pageSize, length) => {
      if (length === 0 || pageSize === 0) {
        return this.outOfZero + length;
      }
      length = Math.max(length, 0);

      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} ${this.outOf} ${length}`;
    };

    this.changes.next();
  }

  private setPaginationsText(): void {
    const setTextSub = this.profileService.currentLang$
      .subscribe(lang => {
        switch (lang.code) {
          case 'uk':
            this.itemsPerPageLabel = 'Елементів на сторінці:';
            this.nextPageLabel = 'Далі';
            this.previousPageLabel = 'Назад';
            this.outOfZero = '0 з ';
            this.outOf = 'з';
            break;
          case 'en':
            this.itemsPerPageLabel = 'Items per page:';
            this.nextPageLabel = 'Next';
            this.previousPageLabel = 'Back';
            this.outOfZero = '0 out of ';
            this.outOf = 'out of';
            break;
          case 'ru':
            this.itemsPerPageLabel = 'Элементов на странице:';
            this.nextPageLabel = 'Дальше';
            this.previousPageLabel = 'Назад';
            this.outOfZero = '0 из ';
            this.outOf = 'из';
            break;
        }
      });

    this.subscriptions.push(setTextSub);
  }
}
