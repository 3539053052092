import {Component, Inject, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {Consultation} from '@consultations/interfaces/consultation.interface';
import {DirectionFieldsEnum} from '@shared-modules/medications/enums/direction-fields.enum';
import {DirectionsInterface} from '@shared-modules/medications/interfaces/directions.interface';
import {ConsultationsService} from '@consultations/consultations.service';

@UntilDestroy()
@Component({
  selector: 'app-modal-directions',
  templateUrl: './modal-directions.component.html',
  styleUrls: ['./modal-directions.component.scss']
})
export class ModalDirectionsComponent implements OnInit {
  directionsFieldsEnum = DirectionFieldsEnum;
  consultation: Consultation;

  directions: DirectionsInterface = {
    analyzes: this.data.consultation.analyzes || '',
    referralsSpecialists: this.data.consultation.referralsSpecialists || '',
    referralsExamination: this.data.consultation.referralsExamination || ''
  }

  constructor(
    private dialogRef: MatDialogRef<ModalDirectionsComponent>,
    private consultationsService: ConsultationsService,
    @Inject(MAT_DIALOG_DATA) private data: { consultation: Consultation }
  ) {
  }

  ngOnInit(): void {
    this.consultation = this.data.consultation;
  }

  saveDirections(): void {
    this.consultationsService.addConsultationsDirections(this.data.consultation.id, this.directions)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.dialogRef.close(true));
  }

  closeModal(): void {
    this.dialogRef.close(false);
  }

  setFieldValue(event): void {
    switch (event.fieldType) {
      case DirectionFieldsEnum.Doctors:
        this.directions.referralsSpecialists = event.text;
        break;
      case DirectionFieldsEnum.Analizes:
        this.directions.analyzes = event.text;
        break;
      case DirectionFieldsEnum.Examination:
        this.directions.referralsExamination = event.text;
        break;
    }
  }
}
