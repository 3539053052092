import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {filter} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

import {
  CreateConsultationResultFilter,
  GetConsultationByIdFilter
} from '@consultations/interfaces/consultations-filter.interface';
import {Consultation} from '@consultations/interfaces/consultation.interface';
import {
  RecommendationFormDataInterface
} from '@shared-modules/medications/interfaces/apointment-form.interface';
import {ConsultationsService} from '@consultations/consultations.service';
import {ModalInfoService} from '@core/services/modal-info.service';
import {
  AppointmentFormService
} from '@shared-modules/medications/services/appointment-form.service';
import {popStateListener} from '../../helper';
import {ModalInformComponent} from './modal-inform/modal-inform.component';
import {WaitingAreaService} from '../../dashboard/waiting-area/services/waiting-area.service';

@UntilDestroy()
@Component({
  selector: 'app-consultation-result-fill',
  templateUrl: './consultation-result-fill.component.html',
  styleUrls: ['./consultation-result-fill.component.scss']
})
export class ConsultationResultFillComponent implements OnInit, AfterViewInit {
  @Input() consultationFromPreview!: Consultation;
  @Input() offlineConsultationId!: number;
  @Output() closeModal = new EventEmitter();
  @Output() updateConsultation = new EventEmitter();

  params: GetConsultationByIdFilter = {
    relation_anamnes: 1,
    relation_patient: 1,
    with_recommendation: 1,
    doctor_with_review: 1
  };

  consultationId: number;
  consultation: Consultation;
  hasMedication: boolean;
  hasDirection: boolean;
  modalInform: any;

  recommendationFormData: RecommendationFormDataInterface;

  constructor(
    private consultationsService: ConsultationsService,
    private route: ActivatedRoute,
    private modalInfoService: ModalInfoService,
    private router: Router,
    private dialog: MatDialog,
    private waitingAreaService: WaitingAreaService,
    private appointmentFormService: AppointmentFormService
  ) {
  }

  ngOnInit(): void {
    if (this.consultationFromPreview) {
      this.consultationId = this.consultationFromPreview.id;
      this.consultation = this.consultationFromPreview;
    } else {
      this.consultationId = this.route.snapshot.params.id || this.offlineConsultationId;
      this.getConsultationResult(this.consultationId);
    }

    this.recommendationFormData = this.appointmentFormService.getValueFromCookie(this.consultationId);
    this.watchRequiredFields();
    this.watchMedicationAndDirection();

    this.enterWaitingArea();
  }

  ngAfterViewInit(): void {
    history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', popStateListener);
  }

  onSubmit(): void {
    const recommendation = {
      appointment_id: this.consultation.id,
      idea: this.recommendationFormData.idea,
      diagnosis: this.recommendationFormData.diagnosis,
      recommendations: this.recommendationFormData.recommendation,
      consultation_end: true
    };

    if (!this.offlineConsultationId) {
      delete recommendation.consultation_end;
    }

    this.createRecommendation(recommendation);
  }

  createRecommendation(data: CreateConsultationResultFilter): void {
    if (this.hasDirection || this.hasMedication) {
      this.modalInform = this.dialog.open(ModalInformComponent, {
        data: {
          hasDirection: this.hasDirection,
          hasMedication: this.hasMedication
        },
        panelClass: ['primary-modal', 'modal-sm'],
        autoFocus: false,
        disableClose: true
      });
    }

    this.consultationsService.createConsultationResult(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.appointmentFormService.removeCookie(this.consultationId);

          if (this.modalInform) {
            this.modalInform.close();
          }

          if (this.consultationFromPreview) {
            this.updateConsultation.emit();
            return;
          }

          if (!this.offlineConsultationId) {
            this.router.navigate(['consultation-result/success', this.consultationId]);
            return;
          }

          this.closeModal.emit(true);
        },
        (error) => this.modalInfoService.onError(error)
      );
  }

  private getConsultationResult(id: number): void {
    this.consultationsService.getConsultationById(id, this.params)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
          this.consultation = result.data;

          if (this.consultation.appointment_result?.id) {
            this.router.navigate(['consultation-result/success', this.consultationId]);
          }
        },
        () => this.router.navigate(['not-found'])
      );
  }

  private watchRequiredFields(): void {
    this.appointmentFormService.updateFormData$
      .pipe(
        filter(r => r),
        untilDestroyed(this))
      .subscribe(() => {
        this.recommendationFormData = this.appointmentFormService.getValueFromCookie(this.consultationId);
      });
  }

  private watchMedicationAndDirection(): void {
    combineLatest([
      this.appointmentFormService.hasMedication$,
      this.appointmentFormService.hasDirection$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([hasMedication, hasDirection]) => {
        this.hasMedication = hasMedication;
        this.hasDirection = hasDirection;
      });
  }

  private enterWaitingArea(): void {
    this.waitingAreaService.enterWaitingArea()
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
