<div class="footer">
  <div class="footer_content">
    <div class="footer_info">
      <app-link-social></app-link-social>

      <app-link-support></app-link-support>

      <app-link-personal-data></app-link-personal-data>

    </div>
    <div class="footer_apps">
      <app-apps-links *ngIf="(activeClinic$ | async).id === clinicIds.doctors4ua"></app-apps-links>
    </div>
  </div>
</div>
