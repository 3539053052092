import * as moment from 'moment';

import {ApiConsultation, Consultation} from '@consultations/interfaces/consultation.interface';

export function prepareConsultation(apiEntity: ApiConsultation): Consultation {
  if (!apiEntity) {
    return;
  }

  const data: Consultation = {
    ...apiEntity.attributes,
    id: apiEntity.id,
    duration: moment(apiEntity.attributes.end_time)
      .diff(apiEntity.attributes.start_time, 'minutes'),
  };

  const relationships = apiEntity.relationships;

  if (relationships) {
    if (relationships.waiting_area?.data) {
      data.waitingAreaId = relationships.waiting_area.data.id;
    }
  }

  return data;
}
