<button
  class="menu-button"
  mat-icon-button
  [class.active]="isActive"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
  [matMenuTriggerFor]="menu"
  [disabled]="disableClick"
>
  <span
    class="notifications_icon"
    [matBadge]="total > 99 ? '99+' : total"
    [matBadgeHidden]="total === 0"
  >
    <img src="assets/images/notification_icon.svg">
  </span>
</button>

<mat-menu #menu="matMenu" backdropClass="notifications-menu-backdrop" xPosition="before">
  <div class="notifications-menu"
       (click)="$event.stopPropagation()"
       infiniteScroll
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="5"
       [scrollWindow]="false"
       (scrolled)="onScroll()">
    <ng-container *ngIf="notifications?.length; else emptyResult">
      <div class="menu-header">
        <p class="notifications-counter">{{'Всего уведомлений:' | translate}} {{ total }}</p>

        <a (click)="removeAll($event)" class="notifications-remove-all"
           href="#">{{'Очистить все' | translate}}</a>
      </div>

      <div class="menu-body">
        <app-notification
          *ngFor="let notification of notifications"
          [notification]="notification"
          (action)="menuTrigger.closeMenu(); clicked.emit()"
        ></app-notification>
      </div>
    </ng-container>

    <ng-template #emptyResult>
      <div *ngIf="!isLoading" class="empty-result">
        {{'У Вас нет уведомлений на данный момент.' | translate}}
      </div>
    </ng-template>
  </div>
</mat-menu>
