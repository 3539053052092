import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {phonePattern} from '../../../core/constants/patterns.const';

@Injectable()
export class ProfileFormBuilderService {
  constructor(private fb: FormBuilder) {
  }

  getForm(): FormGroup {
    return this.fb.group({
      avatar: ['', [Validators.required]],
      avatar_original: [null],
      name: ['', [Validators.required, Validators.maxLength(50)]],
      surname: ['', [Validators.required, Validators.maxLength(50)]],
      middle_name: ['', [Validators.required, Validators.maxLength(50)]],
      gender: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      phone: [
        '',
        [
          Validators.pattern(phonePattern),
          Validators.required,
          Validators.maxLength(12)
        ]
      ],
      code: ['', [Validators.required, Validators.maxLength(4)]],
      email: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', Validators.maxLength(1000)],
      educations: this.fb.array([this.createItem()]),
      jobs: this.fb.array([this.createJobItem()]),
      lang: [''],
      stamp: [null],
      signature: [null, Validators.required]
    });
  }

  createItem(): FormGroup {
    return  this.fb.group({
      id: '',
      specializationIds: ['', [Validators.required]],
      university: ['', [Validators.required, Validators.maxLength(500)]],
      document: [[]],
      document_number: ['', Validators.maxLength(255)]
    });
  }

  createJobItem(): FormGroup {
    return this.fb.group({
      id: '',
      place: ['', [Validators.required, Validators.maxLength(500)]],
      position: ['', [Validators.required, Validators.maxLength(500)]],
      current: [0]
    });
  }
}
