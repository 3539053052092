import * as moment from 'moment';

import {NotificationTypesEnum} from '../enums/notification-types.enum';
import {
  NotificationInitiator,
  Notification
} from '../../notifications/interfaces/notification.interface';
import {ConsultationStatusesEnum} from '@consultations/enums/consultation-statuses.enum';

const prepareNotificationByTypeHandlers = {
  [NotificationTypesEnum.CancelConsultationPatient]: prepareCancelConsultation,
  [NotificationTypesEnum.NewDiscussionMessage]: prepareNewDiscussionMessage,
  [NotificationTypesEnum.CreateConsultation]: prepareCreateConsultation,
};

function defaultNotificationData(item, users) {
  const id = item.attributes.data.initiator_id;
  const type = item.attributes.data.initiator_type;
  const initiatorCandidate = `${id}_${type}`;
  const fullName = item.attributes.data.fullname || `${users[initiatorCandidate].data.attributes.surname || ''}
    ${users[initiatorCandidate].data.attributes.name || ''}
    ${users[initiatorCandidate].data.attributes.middle_name || ''}`;

  const initiator: NotificationInitiator = {
    id,
    type,
    fullName,
    avatar: item.attributes?.data?.avatar || users[initiatorCandidate].data?.attributes?.avatar,
    avatarStorage: item.attributes?.data?.storage || users[initiatorCandidate].data?.attributes?.avatar_storage
  };

  return {
    initiator,
    id: item.id,
    createdAt: item.attributes.created_at,
    userId: item.attributes.user_id,
    userType: item.attributes.user_type,
    status: item.attributes.status,
    sendTo: item.attributes.data.send_to,
    type: item.attributes.data.type,
    time: moment.unix(item.attributes.data.time),
  };
}

function prepareCancelConsultation() {
  return {
    iconName: 'icon-notification-cancel-consultation',
    message: 'отменил',
    action: {
      text: 'консультацию',
      target: `/consultations/canceled`,
    },
  };
}

function prepareNewDiscussionMessage(itemData) {
  return {
    iconName: 'icon-message',
    message: 'написал вам новое',
    action: {
      text: 'сообщение',
      target: `/dialogues/${itemData.initiator_id}/${itemData.discussion_id}`,
    },
  };
}

function prepareCreateConsultation(itemData) {
  return {
    iconName: 'icon-notification-consultation-crated',
    message: 'записался к Вам на консультацию',
    action: {
      text: `${moment(itemData.consultation_start_time).format('DD.MM.YYYY HH:mm')}`,
      target: '/consultations/planned',
    },
  };
}

export function prepareNotification(item, users): Notification {
  return {
    ...defaultNotificationData(item, users),
    ...prepareNotificationByTypeHandlers[item.attributes.data.type](item.attributes.data)
  };
}

export function prepareNewNotification(item): Notification {
  const initiator: NotificationInitiator = {
    id: item.initiator_id,
    type: item.initiator_type,
    avatar: item.avatar,
    avatarStorage: item.storage,
    fullName: item.fullname
  };

  return {
    initiator,
    time: moment.unix(item.time),
    type: item.type,
    userType: item.user_type,
    sendTo: item.send_to,
    ...prepareNotificationByTypeHandlers[item.type](item)
  };
}
