import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

import {MedicationsListComponent} from './medications-list/medications-list.component';
import {ModalAppointmentComponent} from './modals/modal-appointment/modal-appointment.component';
import {ModalMedicationComponent} from './modals/modal-medication/modal-medication.component';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {ModalDirectionsComponent} from './modals/modal-directions/modal-directions.component';
import {MaterialModule} from '../../shared/material/material.module';
import {ValidatorMessageModule} from '@shared-modules/validator-message/validator-message.module';
import {TimeFieldComponent} from './modals/modal-medication/time-field/time-field.component';
import {ModalHeaderModule} from '@shared-modules/modals/modal-header/modal-header.module';
import {NumberFieldModule} from '@core/directives/number-field/number-field.module';
import {
  DirectionFieldComponent
} from './modals/modal-directions/direction-field/direction-field.component';


@NgModule({
  declarations: [
    MedicationsListComponent,
    ModalAppointmentComponent,
    ModalMedicationComponent,
    ModalDirectionsComponent,
    TimeFieldComponent,
    DirectionFieldComponent,
  ],
  exports: [
    MedicationsListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ValidatorMessageModule,
    ModalHeaderModule,
    NumberFieldModule,
    NgxMaterialTimepickerModule,
    MatTabsModule
  ]
})
export class MedicationsModule {
}
