<div class="field direction">
  <mat-form-field appearance="outline">
    <mat-label>{{ fieldType.label | translate }}</mat-label>

    <textarea
      matInput
      [maxlength]="1000"
      [rows]="4"
      [formControl]="direction"
      cdkTextareaAutosize
    ></textarea>
  </mat-form-field>
</div>
