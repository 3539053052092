export const localToken = 'doctors4ua-token';
export const instructionUrl = 'https://www.youtube.com/embed/tgFuponZtAQ?rel=0';
export const googlePlayLink = 'https://play.google.com/store/search?q=doctors4ua';
export const appStoreLink = 'https://apps.apple.com/gb/app/doctors4ua/id1614845101';

export const dateMomentFormat = 'DD.MM.YYYY';
export const dateDoctorFormat = 'YYYY.MM.DD';
export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: dateMomentFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
