import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';

import {MedicationTypesEnum} from '@shared-modules/medications/enums/medication-types.enum';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss']
})
export class TimeFieldComponent implements OnChanges {
  @Input() selectedType: number;
  @Input() times?: string[] = [];
  @Output() setTimes: EventEmitter<string[]> = new EventEmitter;

  medicationTypesEnum = MedicationTypesEnum;

  maxCount = 24;

  selectedTimes: string[];

  constructor() {
  }

  ngOnChanges(): void {
    this.selectedTimes = this.times;
  }

  selectTime(event: string): void {
    const timeSet = new Set(this.selectedTimes);
    timeSet.add(event);
    this.selectedTimes = [...timeSet];

    this.setTimes.emit(this.selectedTimes);
  }

  remove(index: number): void {
    this.selectedTimes.splice(index, 1);

    this.setTimes.emit(this.selectedTimes);
  }
}
