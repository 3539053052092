import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {PatientsService} from '../patients/patients.service';
import {ProfileService} from '@profile/profile.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {Patient} from '@core/interfaces/patient.interface';
import {DoctorsService} from '@profile/services/doctors.service';
import {GetDoctorByIdParamsInterface} from '@core/interfaces/doctor/doctor-params.interface';

@Component({
  selector: 'app-patients-invite',
  templateUrl: './patients-invite.component.html',
  styleUrls: ['./patients-invite.component.scss']
})
export class PatientsInviteComponent implements OnInit {
  patientId!: number;
  patient$: Observable<Patient>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public modal: MatDialogRef<PatientsInviteComponent>,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private doctorsService: DoctorsService,
    private patientsService: PatientsService,
  ) {
  }

  ngOnInit(): void {
    this.patientId = Number(this.data.patientId);
    this.patient$ = this.patientsService.byId(this.patientId, {});
  }
}
