import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {getObjectUrlParams} from '../utils/filter-converter';
import {environment} from '../../../environments/environment';
import {prepareSpecialization} from '../utils/prepare-entity/prepare-specialization';
import {GetSpecializationsParams} from '../interfaces/specialization/specialization-params.interface';
import {
  ApiSpecialization,
  Specialization
} from '../interfaces/specialization/specialization.interface';

@Injectable()
export class SpecializationService {
  private doctors4uaUrl = `${environment.doctors4uaUrl}/specialization`;

  constructor(private http: HttpClient) {
  }

  getSpecializations(filter: GetSpecializationsParams): Observable<Specialization[]> {
    const params = getObjectUrlParams(filter);

    return this.http.get<ApiSpecialization[]>(this.doctors4uaUrl, {params})
      .pipe(map((result: ApiSpecialization[]) => {
        return result.map((item: ApiSpecialization) => {
          return prepareSpecialization(item);
        });
      }));
  }
}
