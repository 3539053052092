import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ValidatorMessageComponent} from './validator-message.component';
import {TranslateModule} from '@core/pipes/translate/translate.module';

@NgModule({
  declarations: [ValidatorMessageComponent],
    imports: [CommonModule, TranslateModule],
    exports: [ValidatorMessageComponent]
})
export class ValidatorMessageModule {
}
