import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

import {DoctorSearchComponent} from './doctor-search.component';
import {DoctorSearchService} from './doctor-search.service';
import {SpecializationService} from '@core/services/specialization.service';
import {ValidatorMessageModule} from '../validator-message/validator-message.module';
import {EmptyResultModule} from '../empty-result/empty-result.module';
import {SpecializationNamesModule} from '../specialization-names/specialization-names.module';
import {TranslateModule} from "@core/pipes/translate/translate.module";


@NgModule({
  declarations: [
    DoctorSearchComponent
  ],
    imports: [
        CommonModule,
        ValidatorMessageModule,
        EmptyResultModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        SpecializationNamesModule,
        TranslateModule
    ],
  exports: [
    DoctorSearchComponent
  ],
  providers: [
    DoctorSearchService,
    SpecializationService
  ]
})
export class DoctorSearchModule {
}
