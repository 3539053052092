import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';

import {Doctor} from '@core/interfaces/doctor/doctor.interface';
import {ProfileService} from '@profile/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @Input() disableClick!: boolean;

  profile$: Observable<Doctor> = this.profileService.profile$;

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {
  }

  gotoProfile(): void {
    this.router.navigate(['profile']);
  }
}
