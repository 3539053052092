import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';

import {JsonaService} from '../../../core/services/jsona.service';
import {
  MedicationInterface,
  MedicationListFilterInterface
} from '../interfaces/medication.interface';
import {DeserializedJsonInterface} from '../../../core/interfaces/jsona.interface';
import {getObjectUrlParams} from '../../../core/utils/filter-converter';

@Injectable({
  providedIn: 'root'
})
export class MedicationsService {
  private medicationUrl = `${environment.doctors4uaUrl}/medication-schedules`;
  constructor(
    private http: HttpClient,
    private jsona: JsonaService
  ) {
  }

  getMedications(filter: MedicationListFilterInterface): Observable<DeserializedJsonInterface<MedicationInterface[]>> {
    const params = getObjectUrlParams(filter);

    return this.http.get(`${this.medicationUrl}`, {params})
      .pipe(
        map(res => this.jsona.deserialize(res))
      );
  }

  manageMedication(medication: MedicationInterface, id = null): Observable<MedicationInterface> {
    if (!id) {
      return this.createMedication(medication);
    }

    return this.updateMedication(medication, id);
  }

  createMedication(medication: MedicationInterface): Observable<any> {
    return this.http.post(`${this.medicationUrl}`, medication)
      .pipe(
        map(res => this.jsona.deserialize(res)?.data)
      );
  }

  updateMedication(medication: MedicationInterface, id: number): Observable<any> {
    return this.http.put(`${this.medicationUrl}/${id}`, medication)
      .pipe(
        map(res => this.jsona.deserialize(res).data)
      );
  }

  deleteMedication(id: number): Observable<any> {
    return this.http.delete(`${this.medicationUrl}/${id}`);
  }
}
