import {Component, Input} from '@angular/core';

import {ProfileService} from '@profile/profile.service';
import {ClinicIdsEnum} from '@core/enums/clinic-ids.enum';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent {
  @Input() isHeaderShortened: boolean;

  activeClinic$ = this.profileService.activeClinic$;
  clinicIds = ClinicIdsEnum;

  constructor(private profileService: ProfileService) {
  }
}
