import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

import {StartEducationComponent} from './components/start-education/start-education.component';
import {EducationStepComponent} from './components/education-step/education-step.component';
import {EducationComponent} from './education.component';
import {TranslateModule} from "@core/pipes/translate/translate.module";


@NgModule({
  declarations: [
    StartEducationComponent,
    EducationStepComponent,
    EducationComponent,
  ],
    imports: [
        CommonModule,
        OverlayModule,
        PortalModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule
    ],
  exports: [
    EducationComponent,
    EducationStepComponent
  ]
})
export class EducationModule {
}
