export const LocalesConst = [
  {
    code: 'uk',
    locale: 'uk-UK',
  },
  {
    code: 'en',
    locale: 'en-EN'
  },
  {
    code: 'ru',
    locale: 'ru-RU'
  }
];
