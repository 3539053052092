<div class="notification-layout" [class.new-notification]="isNewNotification">
  <div class="avatar">
    <ng-container *ngIf="isInitiatorAdmin; else userAvatar">
      <app-safe-image [default]="'default-avatar-admin.png'"></app-safe-image>
    </ng-container>

    <ng-template #userAvatar>
      <app-safe-image
        [name]="notification?.initiator?.avatar"
        [storage]="notification?.initiator?.avatarStorage"
      ></app-safe-image>
    </ng-template>
  </div>

  <div class="info">
    <div class="message">
      <span class="name" *ngIf="!isInitiatorAdmin">
        {{ notification?.initiator?.fullName }}
      </span>

      <span>{{ notification?.message | translate }}</span>

      <span
        class="action"
        *ngIf="notification?.action.target"
        (click)="handleNotificationAction(); hideNotification(notification.id); action.emit()"
      >
        {{ notification.action.text | translate }}
      </span>
    </div>
  </div>

  <div class="date" *ngIf="!isNewNotification">{{ notification?.time | dateAsAgo : (currentLang$ | async) }}</div>

  <button type="button" class="btn-close" (click)="hideNotification(notification.id)">
    <mat-icon>close</mat-icon>
  </button>
</div>
