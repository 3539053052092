export enum ConsultationStatusesEnum {
  Deleted = 0,
  Prepare = 1,
  Started = 2,
  Finished = 3,
  Failed = 4,
  Proposed = 5,
  FinishedByTime = 6
}

export enum ConsultationStatusesNamesEnum {
  past = 'past',
  proposed = 'proposed',
  planned = 'planned',
  canceled = 'canceled',
  live = 'live'
}

/**
 * Meaning of the ConsultationStatusesEnum
 * @param statuses: number[]
 * 0 - (Отмененная) - Врач/пациент отменил консультацию.
 * 1 - (Временная) - ID пациента, ID комнаты, ID врача нет(поиск врача или пациент ждет врача) (проверять на онлайн)
 * 1 - (Созданная закрытая, чат еще не начат) - ID пациента, ID комнаты, ID врача, идет минута ожидания (проверять на онлайн)
 * 2 - (Активная) - ID пациента, ID комнаты, ID врача, сокет приконнектился к врачу и пациенту, чат идет
 * 3 - (Завершенная) - Консультация завершилась по времени/пациент завершил консультацию, комната перестала быть активной
 * 4 - (Неудачная) - Врач/пациент не подключился к комнате (или оба) со статусом 3 за все время консультации
 * 5 - (Ожидает подтверждения) - Врач пригласил пациента
 * 6 - (Завершена по времени) - оффлайн-консультация завершилась по истечению отведенного времени
 */
