<div class="calendar-header">
  <button
    mat-icon-button
    aria-label="Prev month"
    [disabled]="!previousEnabled()"
    (click)="previousClicked()"
    [attr.aria-label]="prevButtonLabel"
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>

  <span class="calendar-label">{{ label }}</span>

  <button
    mat-icon-button
    aria-label="Next month"
    [disabled]="!nextEnabled()"
    (click)="nextClicked()"
    [attr.aria-label]="nextButtonLabel"
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
