import {Component} from '@angular/core';

@Component({
  selector: 'app-consultation-result-success',
  templateUrl: './consultation-result-success.component.html',
  styleUrls: ['./consultation-result-success.component.scss']
})
export class ConsultationResultSuccessComponent {
}

