export function buildErrorMessage(error): string {
  let errorMessage = 'Неизвестная ошибка, попробуйте позже';

  if (error.customClientError) {
    return error.customClientErrorMessage;
  }

  switch (error.status) {
    case 400:
    case 401:
      errorMessage = 'Неверный Email или пароль';
      break;
    case 500:
      errorMessage = 'Ошибка на сервере, попробуйте позже';
      break;
  }

  switch (error.error.code) {
    case 4020:
    case 4028:
    case 7008:
    case 10001:
    case 11006:
    case 11010:
    case 11012:
    case 11013:
    case 11014:
    case 12006:
    case 12007:
    case 20001:
    case 23002:
    case 29000:
    case 29001:
    case 29004:
    case 29005:
    case 29007:
    case 45002:
    case 45003:
    case 45004:
    case 45005:
      errorMessage = error.error.errors;
      break;
    case 1000:
      errorMessage = Object.values(error.error.errors)[0][0];
      break;
  }

  return errorMessage;
}
