import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ModalInfoInterface} from './modal-info.interface';
import {ModalImagesConst} from '@core/constants/modal-images.const';


@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html'
})
export class ModalInfoComponent implements OnInit {
  title = '';
  image: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalInfoInterface) {
  }

  ngOnInit() {
    this.image = this.data.imageType
      ? ModalImagesConst.find(el => el.type === this.data.imageType).image
      : ModalImagesConst[0].image;

    if (this.data.type === 'error') {
      this.image = ModalImagesConst[1].image;
    }

    if (this.data.title && this.data.title !== 'success') {
      return this.title = this.data.title;
    }

    this.title = (this.data.type && this.data.type === 'success') || (this.data.title && this.data.title === 'success')
      ? 'Спасибо!'
      : 'Ошибка!';
  }
}
