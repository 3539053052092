import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment} from '../../../environments/environment';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.portalApi)) {
      return next.handle(req);
    }

    return next.handle(req.clone({
      setHeaders: {
        Timezone: this.parseTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      }
    }));
  }

  private parseTimezone(timezone: string): string {
    switch (timezone) {
      case 'Europe/Kyiv':
        return 'Europe/Kiev';
    }

    return timezone;
  }
}
