import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {getObjectUrlParams} from '../../../core/utils/filter-converter';
import {
  GetMessagesListApiResponseInterface,
  GetMessagesListResponseInterface
} from '../interfaces/message/message-response.interface';
import {
  GetMessagesListParamsInterface,
  UploadFileParamsInterface
} from '../interfaces/message/message-params.interface';
import {buildFormData} from '../../../core/utils/build-form-data';
import {ApiMessageInterface} from '../interfaces/message/message.interface';
import {PrepareMessageFromApi} from '../classes/prepare-message-from-api';

@Injectable({providedIn: 'root'})

export class MessagesService {
  private doctors4uaUrl = `${environment.doctors4uaUrl}/discussion-message`;

  constructor(private http: HttpClient) {
  }

  getMessages(filter: GetMessagesListParamsInterface): Observable<GetMessagesListApiResponseInterface> {
    const params = getObjectUrlParams(filter);

    return this.http.get<GetMessagesListResponseInterface>(this.doctors4uaUrl, {params})
      .pipe(
        map((result: GetMessagesListResponseInterface) => {
          const data = result.data.map((message: ApiMessageInterface) => {
            return new PrepareMessageFromApi(message).message;
          }).reverse();

          return {
            data,
            meta: result.meta
          };
        })
      );
  }

  uploadFile(data: UploadFileParamsInterface): Observable<void> {
    const params: FormData = buildFormData(getObjectUrlParams(data));

    return this.http.post<void>(`${this.doctors4uaUrl}/uploadFile`, params);
  }
}
