export enum DoctorStatusesEnum {
  Delete,
  Active,
  Invited,
  WaitConfirmation,
  EmptyProfileNoSchedule,
  EmptyProfileWithSchedule,
  OnModerateNoSchedule,
  OnModerateWithSchedule,
  ModerateNoSchedule,
  NotActive
}
