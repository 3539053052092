import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalManageConsultationComponent } from './modal-manage-consultation.component';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {TranslateModule} from '../../../core/pipes/translate/translate.module';


@NgModule({
  declarations: [
    ModalManageConsultationComponent
  ],
  imports: [
    CommonModule,
    SafeImageModule,
    TranslateModule
  ]
})
export class ModalManageConsultationModule { }
