import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {PatientsContextSearchFilter} from './interfaces/patients-context-search-filter.interface';
import {
  ApiPatientsContextSearch,
  PatientsContextSearch
} from './interfaces/patients-context-search.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {ApiListResponse} from '@core/interfaces/list-response.interface';

@Injectable()
export class PatientsContextSearchService {
  private doctors4uaUrl = `${environment.doctors4uaUrl}/patient/context`;

  constructor(private http: HttpClient) {
  }

  patientSearch(filter: PatientsContextSearchFilter): Observable<PatientsContextSearch[]> {
    const params = getObjectUrlParams(filter);

    return this.http.get<any>(this.doctors4uaUrl, {params})
      .pipe(
        map(
          (result: ApiListResponse<ApiPatientsContextSearch>) => {
            return result.data.map((patient: ApiPatientsContextSearch) => {
              return {
                id: patient.id,
                type: patient.type,
                fullName: `${patient.attributes.surname} ${patient.attributes.name} ${patient.attributes.middle_name}`,
                ...patient.attributes
              };
            });
          })
      );
  }
}
