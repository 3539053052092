import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

import {NotificationsListComponent} from './components/notifications-list/notifications-list.component';
import {NotificationComponent} from './components/notification/notification.component';
import {SafeImageModule} from '../safe-image/safe-image.module';
import {NewNotificationListenerComponent} from './components/new-notification-listener/new-notification-listener.component';
import {SpinnerModule} from '../spinner/spinner.module';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {DateAsAgoModule} from '@core/pipes/date-as-ago/date-as-ago.module';


@NgModule({
  declarations: [
    NotificationsListComponent,
    NotificationComponent,
    NewNotificationListenerComponent,
  ],
  imports: [
    CommonModule,
    SafeImageModule,
    RouterModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatBadgeModule,
    SpinnerModule,
    TranslateModule,
    InfiniteScrollModule,
    DateAsAgoModule
  ],
  exports: [
    NotificationsListComponent,
    NotificationComponent,
    NewNotificationListenerComponent
  ]
})
export class NotificationsModule {
}
