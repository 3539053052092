import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

import {Notification} from '../../interfaces/notification.interface';
import {newNotificationCloseTimeout} from '../../../notifications/constants/notifications.const';
import {UserIdTypesEnum} from '@core/enums/user-types.enum';
import {
  NotificationDeleteFilter
} from '../../../notifications/interfaces/notification-filter.interface';
import {NotificationsService} from '../../../notifications/services/notifications.service';
import {unsubscribe} from '@core/utils/unsubscriber';
import {ProfileService} from '@profile/profile.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() notification: Notification;

  @Input() isNewNotification = false;

  @Output() closeNewNotification: EventEmitter<null> = new EventEmitter<null>();

  @Output() action: EventEmitter<null> = new EventEmitter<null>();

  isInitiatorAdmin = false;

  newNotificationTimeout: ReturnType<typeof setTimeout>;

  currentLang$: BehaviorSubject<any> = this.profileService.currentLang$;

  private subscriptions: Subscription[] = [];

  constructor(
    private notificationsService: NotificationsService,
    private profileService: ProfileService,
    private router: Router,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isNewNotification && changes.isNewNotification.currentValue) {
      this.clearNotificationTimeout();

      this.newNotificationTimeout = setTimeout(
        () => this.onCloseNewNotification(),
        newNotificationCloseTimeout
      );
    }
  }

  ngOnInit(): void {
    this.isInitiatorAdmin = this.notification?.initiator?.type === UserIdTypesEnum.Admin;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  hideNotification(notificationId: number): void {
    this.isNewNotification
      ? this.onCloseNewNotification()
      : this.removeNotification({ids: [notificationId]});
  }

  handleNotificationAction(): void {
    this.navigateByRoute(this.notification.action.target);
  }

  private removeNotification(params: NotificationDeleteFilter): void {
    const removeNotificationSub = this.notificationsService.removeNotifications(params)
      .subscribe(() => this.notificationsService.notificationsUpdated$.next(true));

    this.subscriptions.push(removeNotificationSub);
  }

  private onCloseNewNotification(): void {
    this.closeNewNotification.emit();

    this.clearNotificationTimeout();
  }

  private clearNotificationTimeout(): void {
    if (this.newNotificationTimeout) {
      clearTimeout(this.newNotificationTimeout);
    }
  }

  private navigateByRoute(route: string): void {
    this.router.navigateByUrl('/consultations', {skipLocationChange: true})
      .then(() => this.router.navigate([route]));
  }
}
