import { Pipe, PipeTransform } from '@angular/core';

import {ProfileService} from '@profile/profile.service';

@Pipe({
  name: 'addressById'
})
export class AddressByIdPipe implements PipeTransform {

  constructor(private profileService: ProfileService) {
  }
  transform(id: number): string {
    if (!id) {
      return '';
    }

    const clinicAddresses = this.profileService.activeClinic$.getValue()?.addresses;
    return clinicAddresses.find(el => el.id === id)?.address;
  }

}
