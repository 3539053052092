<a [routerLink]="['/dialogues']" routerLinkActive="active" class="dialogs-link">
  <span class="text">{{ 'Сообщения' | translate }}</span>

  <span
    [matBadgePosition]="'before'"
    [matBadge]="unreadMessagesCount$ | async"
    [matBadgeHidden]="(unreadMessagesCount$ | async) === 0"
  >
  </span>
</a>
