import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';

import {ConsultationsService} from '../dashboard/consultations/consultations.service';
import {ConsultationResultRoutingModule} from './consultation-result.routing.module';
import {ValidatorMessageModule} from '../shared-modules/validator-message/validator-message.module';
import {SafeImageModule} from '../shared-modules/safe-image/safe-image.module';
import {TranslateModule} from '../core/pipes/translate/translate.module';
import {MaterialModule} from '../shared/material/material.module';
import {
  ConsultationResultFillComponent
} from './consultation-result-fill/consultation-result-fill.component';
import {
  ConsultationResultSuccessComponent
} from './consultation-result-success/consultation-result-success.component';
import {MedicationsModule} from '@shared-modules/medications/medications.module';
import {ConsultationResultComponent} from './consultation-result.component';
import {
  SuccessContentComponent
} from './consultation-result-success/success-content/success-content.component';
import {ModalInformComponent} from './consultation-result-fill/modal-inform/modal-inform.component';
import {ImagePathModule} from '@core/pipes/image-path/image-path.module';
import {ModalHeaderModule} from '@shared-modules/modals/modal-header/modal-header.module';

@NgModule({
  declarations: [
    ConsultationResultFillComponent,
    ConsultationResultSuccessComponent,
    ConsultationResultComponent,
    SuccessContentComponent,
    ModalInformComponent
  ],
  imports: [
    CommonModule,
    ConsultationResultRoutingModule,
    ValidatorMessageModule,
    SafeImageModule,
    TranslateModule,
    MaterialModule,
    MedicationsModule,
    MatSnackBarModule,
    ImagePathModule,
    ModalHeaderModule
  ],
  exports: [
    ConsultationResultFillComponent,
    SuccessContentComponent
  ],
  providers: [
    ConsultationsService
  ]
})
export class ConsultationResultModule {
}
