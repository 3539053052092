import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatListModule} from '@angular/material/list';
import {NgSelectModule} from '@ng-select/ng-select';
import {ReactiveFormsModule} from '@angular/forms';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {AppLayoutComponent} from './app-layout.component';
import {HeaderComponent} from './components/header/header.component';
import {EducationModule} from '../../education/education.module';
import {NotificationsModule} from '../../notifications/notifications.module';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {ClinicComponent} from './components/clinic/clinic.component';
import {ProfileComponent} from './components/profile/profile.component';
import {LogoComponent} from './components/logo/logo.component';
import {
  LinkConsultationsComponent
} from './components/link-consultations/link-consultations.component';
import {LinkScheduleComponent} from './components/link-schedule/link-schedule.component';
import {LinkSupportComponent} from './components/link-support/link-support.component';
import {LinkFilesComponent} from './components/link-files/link-files.component';
import {LinkMyPatientsComponent} from './components/link-my-patients/link-my-patients.component';
import {LinkDialoguesComponent} from './components/link-dialogues/link-dialogues.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {
  LinkDataTemplatesComponent
} from './components/link-data-templates/link-data-templates.component';
import {LinkSocialComponent} from './components/link-social/link-social.component';
import {AppsLinksComponent} from './components/apps-links/apps-links.component';
import {TranslateModule} from '@core/pipes/translate/translate.module';
import {
  FooterComponent
} from '@shared-modules/layouts/app-layout/components/footer/footer.component';
import {
  LinkPersonalDataComponent
} from './components/link-personal-data/link-personal-data.component';
import {LanguageSelectComponent} from './components/language-select/language-select.component';
import {ProfileFormBuilderService} from '@profile/services/profile-form-builder.service';
import {
  NearestConsultationComponent
} from './components/nearest-consultation/nearest-consultation.component';
import {ImagePathModule} from '@core/pipes/image-path/image-path.module';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';
import {MessagesService} from "../../../dashboard/dialogues/services/messages.service";

@NgModule({
  declarations: [
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    ClinicComponent,
    ProfileComponent,
    LogoComponent,
    LinkConsultationsComponent,
    LinkScheduleComponent,
    LinkSupportComponent,
    LinkFilesComponent,
    LinkMyPatientsComponent,
    LinkDialoguesComponent,
    SidenavComponent,
    LinkDataTemplatesComponent,
    LinkSocialComponent,
    AppsLinksComponent,
    LinkPersonalDataComponent,
    LanguageSelectComponent,
    NearestConsultationComponent,
    FilePreviewComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    MatListModule,
    EducationModule,
    SafeImageModule,
    NotificationsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule,
    ImagePathModule,
    SpinnerModule,
    PdfViewerModule
  ],
  exports: [
    AppLayoutComponent
  ],
  providers: [
    ProfileFormBuilderService,
    MessagesService
  ]
})
export class AppLayoutModule {
}
