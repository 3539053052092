import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppGuard} from './app.guard';
import {AuthGuard} from '@auth/auth.guard';
import {AppLayoutComponent} from '@shared-modules/layouts/app-layout/app-layout.component';
import {MyNetworkErrorComponent} from './chat-errors/my-network-error/my-network-error.component';
import {
  PatientNetworkErrorComponent
} from './chat-errors/patient-network-error/patient-network-error.component';

const routes: Routes = [
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'consultations'
      },
      {
        path: 'wizard',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./dashboard/wizard/wizard.module').then((m) => m.WizardModule)
      },
      {
        path: 'consultations',
        loadChildren: () =>
          import('./dashboard/consultations/consultations.module').then(
            (m) => m.ConsultationsModule
          )
      },
      {
        path: 'my-patients',
        loadChildren: () =>
          import('./dashboard/patients/patients.module').then((m) => m.PatientsModule)
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./dashboard/schedule/schedule.module').then((m) => m.ScheduleModule)
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./dashboard/profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./dashboard/support/support.module').then((m) => m.SupportModule)
      },
      {
        path: 'dialogues',
        loadChildren: () =>
          import('./dashboard/dialogues/dialogues.module').then((m) => m.DialoguesModule)
      },
      {
        path: 'clinics',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./clinics/clinics.module').then((m) => m.ClinicsModule)
      },
      {
        path: 'chat/:id',
        data: {isHeaderShortened: true, isSidebarHidden: true, isNewNotificationsHidden: true},
        loadChildren: () => import('./chat-room/chat-room.module').then((m) => m.ChatRoomModule)
      },
      {
        path: 'chat-history/:id',
        loadChildren: () =>
          import('./chat-history/chat-history.module').then((m) => m.ChatHistoryModule)
      },
      {
        path: 'consultation-result',
        data: {isHeaderShortened: true, isSidebarHidden: true, isNewNotificationsHidden: true},
        loadChildren: () =>
          import('./consultation-result/consultation-result.module').then(
            (m) => m.ConsultationResultModule
          )
      },
      {
        path: 'patient-network-error/:id',
        data: {isHeaderShortened: true, isSidebarHidden: true, isNewNotificationsHidden: true},
        component: PatientNetworkErrorComponent
      },
      {
        path: 'my-network-error/:id',
        canActivate: [AppGuard],
        component: MyNetworkErrorComponent
      },
      {
        path: 'not-found',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      },
      {
        path: '**',
        data: {isSidebarHidden: true},
        loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
