export const FrequencyListConst = [
  {
    frequency: 1,
    title: 'Каждый день'
  },
  {
    frequency: 2,
    title: 'Через день'
  },
  {
    frequency: 3,
    title: 'Раз в 3 дня'
  },
  {
    frequency: 4,
    title: 'Раз в 4 дня'
  },
  {
    frequency: 5,
    title: 'Раз в 5 дней'
  },
  {
    frequency: 6,
    title: 'Раз в 6 дней'
  },
  {
    frequency: 7,
    title: 'Раз в неделю'
  }
]
