import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberFieldDirective} from './number-field.directive';


@NgModule({
  declarations: [
    NumberFieldDirective
  ],
  exports: [
    NumberFieldDirective
  ],
  imports: [
    CommonModule
  ]
})
export class NumberFieldModule {
}
