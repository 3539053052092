import {WindowSizesEnum} from '../enums/window-sizes.enum';
import {WindowSizeConfigInterface} from '../interfaces/window-size-config.interface';

export function prepareWindowSizeConfig(windowSize: number): WindowSizeConfigInterface {
  return {
    isTablet: windowSize >= WindowSizesEnum.TabletBreakpoint,
    isSmallDesktop: windowSize >= WindowSizesEnum.SmallDesktopBreakpoint,
    isLargeDesktop: windowSize >= WindowSizesEnum.LargeDesktopBreakpoint,
  };
}

/**
 * Meaning of the WindowSizesEnum
 * isTablet: >= 768px
 * isSmallDesktop: >= 1200px
 * isLargeDesktop: >= 1440px
 */
