<div class="modal_wrap">
  <img class="modal-img-state" [src]="'recommendation.svg' | imagePath" alt="Doctors4UA"/>


  <div class="dialog-content">

    <!-- only for directions -->
    <p class="text-center" *ngIf="data.hasDirection && !data.hasMedication">
      {{ 'Идет создание PDF файла направлений' | translate }}
    </p>

    <!-- only for medications -->
    <p class="text-center" *ngIf="!data.hasDirection && data.hasMedication">
      {{ 'Идет создание PDF файла рецепта' | translate }}
    </p>

    <!-- for medications and directions -->
    <p class="text-center" *ngIf="data.hasDirection && data.hasMedication">
      {{ 'Идет создание PDF файлов рецепта и направлений' | translate }}
    </p>
  </div>
</div>
